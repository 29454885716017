import React, { useEffect } from "react"
import { storedata } from "../../../data/Stores/data"
import { Link } from "react-router-dom";
export default function Store({data}){
 
    return(
        <div class="row mt-4">
            {data.map((ele)=>{
                return(
                    <>
                    <div class="col-sm-6">
                    <div class="set">
                    <Link to={`/stores/${ele.ID}`}>
                    <img src={ele.thumb} alt="img"/>
                    </Link>
                    <div class="row">
                        <div class="col-6">
                        <h6><i class="fa-solid fa-message"></i>{ele.TOTALCOMMENTS}</h6>
                    </div>
                    <div class="col-6">
                    <h6><i class="fa-solid fa-star"></i> {ele.RATING}</h6>
                    </div>
                    </div>
                    </div>
                        </div>
                    </>

                )
            })}
        
     </div>
    )
}