import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dealsDetailsContent } from "./api";
import { fetchdealofdayContent } from "./api";

export const getDealOfDayContent = createAsyncThunk(
  "dealsofday/fetchdeal",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchdealofdayContent(formData);
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const DealofDaySlice = createSlice({
  name: "dealofday",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDealOfDayContent.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getDealOfDayContent.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(getDealOfDayContent.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default DealofDaySlice.reducer;
export const { clearAction } = DealofDaySlice.actions;
