import React from "react";
import { Link } from "react-router-dom";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import SingleArticle from "./SingleArticle.js";
import DayDeals from "../SingleProduct/DayDeals.js";
import Form from "../SingleProduct/Form.js";
import Footerinfo from "../../Home/Footerinfo.js";
import { useParams } from 'react-router-dom';
import CommentForm from "./CommentForm.js";
import NewsComments from "./Comments.js";
export default function NewsArticleSingle(){
    const { id } = useParams();
    return(
        <>
        <section class="safety_tips singlenews safety_trips-al">
        <div class="container">
        <h6><Link to="/">Home</Link>&lt; News & Articles </h6>
        <div class="row">
            <div class="col-lg-8 col-xxl-9">
            <SingleArticle id={id}/>
            <NewsComments id={id}/>
            </div>
            <div class="col-lg-4 col-xxl-3">
            <DayDeals/>
            </div>
            </div>
            <CommentForm id ={id}/>
            </div>
            </section>
            <Footerinfo/>
               
        </>
    )
}