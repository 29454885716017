import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { addToFavDeals, fetchFavDeals, removeFavDeals } from "./api";

export const fetchFavoritesDeals = createAsyncThunk(
  "favoritedeals/getDeal",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchFavDeals(formData);
      return response.data.responseData;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const addToFavorites = createAsyncThunk(
  "favoritedeals/addDeal",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await addToFavDeals(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const removeFromFavorites = createAsyncThunk(
  "favoritedeals/removeDeal",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await removeFavDeals(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const FavoritesSlice = createSlice({
  name: "favoritedeals",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    addsuccess: false,
    content: [],
    favoritedealcontent: [],
    removesuccess: false
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
      state.addsuccess = false;
      state.removesuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFavoritesDeals.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchFavoritesDeals.fulfilled, (state, action) => {
        state.submitting = false;
        state.favoritedealcontent = action.payload.data ? action.payload.data : [];
        state.success = true;
        state.error = null;
      })
      .addCase(fetchFavoritesDeals.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
        state.favoritedealcontent = [];
      })
      .addCase(addToFavorites.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.addsuccess = false;
      })
      .addCase(addToFavorites.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.addsuccess = true;
        state.error = null;
      })
      .addCase(addToFavorites.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.addsuccess = false;
      })
      .addCase(removeFromFavorites.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.removesuccess = false;
      })
      .addCase(removeFromFavorites.fulfilled, (state, action) => {
        state.submitting = false;
        state.removesuccess = true;
        state.content = action.payload;
        state.error = null;
      })
      .addCase(removeFromFavorites.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.removesuccess = false;
      });
  },
});
export default FavoritesSlice.reducer;
export const { clearAction } = FavoritesSlice.actions;
