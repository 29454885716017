import { getComments, clearAction, likedislikeComment } from "../../../Reducer/commentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { postComment } from "../../../Reducer/commentSlice";
import SingleComment from "./SingleComment";
export default function DealHistory({dealhistory}){

    return(
        <>
        <div className="deal-history">
          <h3>Deal History</h3>
         {dealhistory.length > 0 && (
        
          <ul>
            {dealhistory.map((ele) => (
                <>
             {/* <SingleComment data ={ele} id={id}/>
              */}
              <li>Posted By {ele.postedby} on {ele.date} Rs {ele.price} {ele.superdeal? 'in superdeal': ''} </li>
            
             </>
            ))}
          </ul>
             )}
        </div>
   
        
        </>
    )
}