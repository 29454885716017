import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const Owlifslist = ({ data}) => {
  
    return (
        <>
        {/* <div class="owl.carousel.min owl-theme nav nav-tabs" id="myTab" role="tablist"> */}
        <Link to={`/stores/${data.ID}`}>

            <div class="item active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">
                <div class="images">
                    <img src={data?.thumb} alt="ele.TITLE" />
                </div>
            </div>
            </Link>
            {/* <div class="item active" id="tab1-tab" data-bs-toggle="tab" data-bs-target="#tab1" type="button" role="tab" aria-controls="tab1" aria-selected="true">
                <div class="images">
                    <img src="assets/images/hawaiian-shirt.png" alt="image" />
                </div>
            </div> */}
        {/* </div> */}
        {/* <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    <div class="row">
                        <div class="col-sm-6 col-md-4 col-xxl-2 mb-3">
                            <div class="set">
                                <div class="hedset">
                                    <img src="assets/images/amazonblack.png" alt="image" />
                                </div>
                                <div class="img">
                                    <img src="assets/images/pro1.png" alt="img" />
                                </div>
                                <h4>Brand: Sjyeza</h4>
                                <div class="content">
                                    <p>Lorem Ipsum has been the industry's standard</p>
                                    <h5>Rs. 200 <span>Rs. 165</span></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-xxl-2 mb-3">
                            <div class="set">
                                <div class="hedset">
                                    <img src="assets/images/amazonblack.png" alt="image" />
                                </div>
                                <div class="img">
                                    <img src="assets/images/pro1.png" alt="img" />
                                </div>
                                <h4>Brand: Sjyeza</h4>
                                <div class="content">
                                    <p>Lorem Ipsum has been the industry's standard</p>
                                    <h5>Rs. 200 <span>Rs. 165</span></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-xxl-2 mb-3">
                            <div class="set">
                                <div class="hedset">
                                    <img src="assets/images/amazonblack.png" alt="image" />
                                </div>
                                <div class="img">
                                    <img src="assets/images/pro1.png" alt="img" />
                                </div>
                                <h4>Brand: Sjyeza</h4>
                                <div class="content">
                                    <p>Lorem Ipsum has been the industry's standard</p>
                                    <h5>Rs. 200 <span>Rs. 165</span></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-xxl-2 mb-3">
                            <div class="set">
                                <div class="hedset">
                                    <img src="assets/images/amazonblack.png" alt="image" />
                                </div>
                                <div class="img">
                                    <img src="assets/images/pro1.png" alt="img" />
                                </div>
                                <h4>Brand: Sjyeza</h4>
                                <div class="content">
                                    <p>Lorem Ipsum has been the industry's standard</p>
                                    <h5>Rs. 200 <span>Rs. 165</span></h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">222</div>
                <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">333</div>
                <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">444</div>
                <div class="tab-pane fade" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">555</div>
            </div> */}
            </>
    )
}
export default Owlifslist;