import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPostComments,postnewComment, commentlikedislike  } from "./api";
export const getComments = createAsyncThunk(
  "comments/getcomments",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchPostComments(formData);
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const likedislikeComment = createAsyncThunk(
  "comments/likedislike",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await commentlikedislike(formData);
      return response.responseData;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const postComment = createAsyncThunk(
  "comments/postcomment",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await postnewComment(formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const CommentsSlice = createSlice({
  name: "postcomments",
  initialState: {
    submitting: false,
    error: null,
    success: false,
      content: [],
    addsuccess: false,
    likesuccess: false
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
          state.addsuccess = false;
          state.likesuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getComments.pending, (state) => {
      state.submitting = true;
      state.error = null;
      state.success = false;
    })
    .addCase(getComments.fulfilled, (state, action) => {
      state.submitting = false;
      state.content = action.payload;
      state.success = true;
      state.error = null;
    })
    .addCase(getComments.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.payload;
      state.success = false;
    })
    .addCase(likedislikeComment.pending, (state) => {
      state.submitting = true;
      state.error = null;
      state.likesuccess = false;
    })
    .addCase(likedislikeComment.fulfilled, (state, action) => {
      state.submitting = false;
      state.likesuccess = true;
      state.error = null;
    })
    .addCase(likedislikeComment.rejected, (state, action) => {
      state.submitting = false;
      state.error = action.payload;
      state.likesuccess = false;
    })
      .addCase(postComment.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.addsuccess = false;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.addsuccess = true;
        state.error = null;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.addsuccess = false;
      });
  },
});
export default CommentsSlice.reducer;
export const { clearAction } = CommentsSlice.actions;
