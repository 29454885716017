import React from "react";
import { Link } from "react-router-dom";
const filterButtonStyle = {
    color: 'white',            
    // padding: '10px 20px',      // Optional: Add padding
    border: 'none',            // Optional: Remove border
    borderRadius: '5px',       // Optional: Add border radius for rounded corners
    cursor: 'pointer',         // Change cursor on hover
    textDecoration: 'none',    // Ensure no underline for the link
    display: 'inline-block',   // Ensure button-like appearance
  };
  const buttonContainerStyle = {
    position: 'fixed',  // Fixed position relative to the viewport
    right: '20px',      // 20px from the right edge
    bottom: '30%',     // 20px from the bottom edge
    zIndex: 1000,       // Ensure it stays on top of other elements
  };
export default function FilterButton() {
  return (
    <div style={buttonContainerStyle}>
    <Link to="/filters" style={filterButtonStyle}>
      <img src="/assets/images/filter_right.png" />
    </Link>
    </div>
  );
}
