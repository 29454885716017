import React, { useEffect, useState } from "react";
import Dailydealifs from "../Tools/Dailydealifs";
import { getDealsContent, clearAction} from '../../Reducer/dealsSlice'
import { useDispatch, useSelector } from 'react-redux';
import { fetchFavoritesDeals } from "../../Reducer/favoriteSlice";
import { getDealsCount, cleardealcountAction } from "../../Reducer/dealcountSlice";
export default function Dailydeal() {
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
    const dispatch = useDispatch();
    const dealscontent = useSelector((state) => state.dealscontent.content);
  const dealssuccessData = useSelector((state) => state.dealscontent.success);
  const dealscountcontent = useSelector((state)=> state.dealscount.content);
  const dealscountsuccess = useSelector((state)=> state.dealscount.success)
  const favoritedealsuccessData = useSelector(
    (state) => state.favoritedeals.success
  );
  const favoritedealContent = useSelector(
    (state) => state.favoritedeals.favoritedealcontent
  );
   const FavoriteAddsuccessData = useSelector(
     (state) => state.favoritedeals.addsuccess
   );
  const FavoriteRemovesuccessData = useSelector(
    (state) => state.favoritedeals.removesuccess
  );
    const [dailydeals, setdailyDeals] = useState("");
  const [superdeals, setSuperDeals] = useState("");
  const [favoritesdeals, setFavoritesDeals] = useState([]);
  const [activeTab, setActiveTab] = useState("Daily_Deals");
  const [loading, setLoading] = useState(false);
  const [dealcount, setDealCount] = useState({});
  const [loadingcount, setLoadingCount] = useState(true);
  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);
  const fetchData = async (tab) => {
    let formData;
    setLoading(true);
    switch (tab) {
      case "Daily_Deals":
        formData = {
          superdeal: 0,
          limit: 8,
          page: 1,
        };
        dispatch(getDealsContent(formData));
        break;
      case "Super_Deals":
        formData = {
          superdeal: 1,
          limit: 8,
          page: 1,
        };
        dispatch(getDealsContent(formData));
        break;

      case "Favorites":
        if (userid) {
          formData={
        userid: userid
      }
        dispatch(fetchFavoritesDeals(formData));
        break;
        }
      
    }
    
    }

  useEffect(()=>{
    let currentDate = new Date();
    let unixTimestamp = Math.floor(currentDate.getTime() / 1000);
    dispatch(getDealsCount({timestamp: unixTimestamp}))
  },[]);
  useEffect(() => {
    if (userid) {
      let formData = {
        userid: userid,
      };
      dispatch(fetchFavoritesDeals(formData));
    }
  }, [userid,FavoriteAddsuccessData, FavoriteRemovesuccessData]);
   
    useEffect(() => {
      let responsedata = "";
      if (dealssuccessData && dealscontent) {
        responsedata = dealscontent;
        console.log(dealscontent, 'dealss');
        dispatch(clearAction());
        switch (activeTab) {
          case "Daily_Deals":
            setdailyDeals(responsedata);
            setLoading(false);
            break;
          case "Super_Deals":
            setSuperDeals(responsedata);
            setLoading(false);
            break;
          default:
            break;
        }
      }
      if (favoritedealsuccessData && favoritedealContent) {
        dispatch(clearAction());
            setFavoritesDeals(favoritedealContent);
            setLoading(false);
        }
      if(dealscountcontent && dealscountsuccess){
        setDealCount(dealscountcontent);
        setLoadingCount(false);
        dispatch(cleardealcountAction());
      }

    }, [dealssuccessData, dealscontent, activeTab, favoritedealContent, dealscountsuccess]);

    return (
      <>
      {!loadingcount && (
        <div className="col-lg-8 col-xxl-9">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "Daily_Deals" ? "active" : ""}`}
              id="Daily_Deals-tab"
              data-bs-toggle="tab"
              data-bs-target="#Daily_Deals"
              type="button"
              role="tab"
              aria-controls="Daily_Deals"
              aria-selected={activeTab === "Daily_Deals"}
              onClick={() => setActiveTab("Daily_Deals")}
            >
              Daily Deals 
              {!loadingcount && dealcount?.dailydeal !== 0 && <span>{dealcount.dailydeal}</span>}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "Super_Deals" ? "active" : ""}`}
              id="Super_Deals-tab"
              data-bs-toggle="tab"
              data-bs-target="#Super_Deals"
              type="button"
              role="tab"
              aria-controls="Super_Deals"
              aria-selected={activeTab === "Super_Deals"}
              onClick={() => setActiveTab("Super_Deals")}
            >
              Super Deals 
              {!loadingcount && dealcount?.superdeal !== 0 && <span>{dealcount.superdeal}</span>}
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "Favorites" ? "active" : ""}`}
              id="Favorites-tab"
              data-bs-toggle="tab"
              data-bs-target="#Favorites"
              type="button"
              role="tab"
              aria-controls="Favorites"
              aria-selected={activeTab === "Favorites"}
              onClick={() => setActiveTab("Favorites")}
            >
              Favorites 
              {!loadingcount && favoritesdeals?.length !== 0 && <span>{favoritesdeals.length}</span>}
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade ${activeTab === "Daily_Deals" ? "show active" : ""}`}
            id="Daily_Deals"
            role="tabpanel"
            aria-labelledby="Daily_Deals-tab"
          >
            <div className="row">
              {!loading &&
                dailydeals.length > 0 &&
                dailydeals.map((ele, index) => {
                  const favoriteDeal = favoritesdeals.find(fav => fav.deal.ID === ele.ID); 
                const isFav = Boolean(favoriteDeal);
                const favid = favoriteDeal ? favoriteDeal.id : null; 
                  return ( // Ensure you return the component
                    <Dailydealifs deal={ele} key={index} isFav={isFav} favid={favid}/>
                  );
                })}
            </div>
          </div>
      
          <div
            className={`tab-pane fade ${activeTab === "Super_Deals" ? "show active" : ""}`}
            id="Super_Deals"
            role="tabpanel"
            aria-labelledby="Super_Deals-tab"
          >
            <div className="row">
              {!loading &&
                superdeals.length > 0 &&
                superdeals.map((ele, index) => {
                  const favoriteDeal = favoritesdeals.find(fav => fav.deal.ID === ele.ID); 
                  const isFav = Boolean(favoriteDeal);
                  const favid = favoriteDeal ? favoriteDeal.id : null; 
                  return ( // Ensure you return the component
                    <Dailydealifs deal={ele} key={index} isFav={isFav}favid={favid}  />
                  );
  })}
            </div>
          </div>
      
          <div
            className={`tab-pane fade ${activeTab === "Favorites" ? "show active" : ""}`}
            id="Favorites"
            role="tabpanel"
            aria-labelledby="Favorites-tab"
          >
            <div className="row">
            {
              !loading && favoritesdeals.length > 0 ? (
                favoritesdeals.map((ele, index) => (
                  <Dailydealifs deal={ele.deal} favid={ele.id} key={index} isFav={"true"} />
                ))
              ) : (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  No deals added yet.
                </div>
              )
            }
            </div>
          </div>
        </div>
      </div>
      )}
      </>
    )
  }