import React, { useEffect, useState } from "react";
import BannerSection from "./BannerSection";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import Frontbanner from '../../Home/Frontbanner.js'
import Owlcaraousedata from '../../Home/Owlcaraousedata';
import CategoriesSection from "./CategoriesSection.js";
import Dailydeal from '../../Home/Dailydeal';
import DealDay from "../../Home/Dealday";
import FeaturedStores from "../../Home/FeaturedStores";
import Recentcomment from "../../Home/Recentcomment";
import Topcoupon from "../../Home/Topcoupon";
import FeaturedProduct from "../../Home/FeaturedProduct";
import Footerinfo from "../../Home/Footerinfo";
import { useDispatch, useSelector } from "react-redux";
import { getComments } from "../../../Reducer/commentSlice.js";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "../../Tools/Loading/index.js";
export default function Home() {

    return (<>
       <CategoriesSection/>
       <Frontbanner></Frontbanner>
       <Owlcaraousedata></Owlcaraousedata>
        <section class="shop deals_shop">
            <div class="container">
                <div class="row">
       <Dailydeal></Dailydeal>
        <div class="col-lg-4 col-xxl-3">
          <h3>Deals Of The Day</h3>
       <DealDay></DealDay>
        </div>
        </div></div></section>
        <FeaturedStores></FeaturedStores>
        <section class="shop top_coupons button-color">
            <div class="container">
                <div class="row">
                    <Recentcomment></Recentcomment>
                    <Topcoupon></Topcoupon>
                </div>
            </div>
        </section>
        <FeaturedProduct></FeaturedProduct>
        <Footerinfo></Footerinfo>
    </>)
}
