import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, loginForm } from "../../../Reducer/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import {
  changePasswordForm,
  clearAction,
} from "../../../Reducer/changepassSlice";
export default function ChangePassword({ id }) {
    const dispatch = useDispatch();
    const [passwordchange, setPasswordchange] = useState({
      currentpassword: "",
      newpassword: "",
    });
    const [confirmpassword, setConfirmpassword] = useState("");
    const changesuccessData = useSelector((state) => state.changepass.success);
    const changerespoData = useSelector((state) => state.changepass.content);
    const changeerrorData = useSelector((state) => state.changepass.error);
    if(changesuccessData){
        if (changerespoData.status == 'success') {
            toast.success(changerespoData.responseData.message); 
          setPasswordchange({
              currentpassword: "",
              newpassword: "",
          });
            setConfirmpassword("");
           dispatch(clearAction());
        }
        else if (changerespoData.status == "failure") {
            toast.error(changerespoData.responseData.message);
            dispatch(clearAction());
        }
    }
    const handleChange = (e) => {
      const { name, value } = e.target;
      setPasswordchange((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };
    const handlecpassChange = (e) =>{
        setConfirmpassword(e.target.value);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (passwordchange.newpassword !== confirmpassword) {
            toast.error('Password doesnt Match')
            return;
        }
          if (
            validator.isLength(passwordchange.currentpassword, { min: 8 }) &&
            validator.isLength(passwordchange.newpassword, { min: 8 })
          ) {
              let formdata = new FormData();
            formdata.append("userid", id);
            formdata.append("current_password", passwordchange.currentpassword);
            formdata.append("new_password", passwordchange.newpassword);
            dispatch(changePasswordForm(formdata));
          } else {
            toast.error("Password must be atleast 8 character long");
          }
    };
  return (
    <div
      className="tab-pane fade"
      id="changepassword"
      role="tabpanel"
      aria-labelledby="changepassword-tab"
    >
      <div className="row mt-4">
        <div className="col-sm-6">
          <label>Old Password</label>
          <input
            type="password"
            placeholder="Old Password"
            className="form-control"
            name="currentpassword"
            onChange={handleChange}
            value={passwordchange.currentpassword}
          />
        </div>

        <div className="col-sm-6">
          <label>New Password</label>
          <input
            type="password"
            placeholder="New Password"
            className="form-control"
            name="newpassword"
            onChange={handleChange}
            value={passwordchange.newpassword}
          />
        </div>

        <div className="col-sm-6">
          <label>Confirm New Password</label>
          <input
            type="password"
            placeholder="Confirm New Password"
            className="form-control"
            name="confirmpassword"
            value={confirmpassword}
            onChange={handlecpassChange}
          />
        </div>

        <div className="col-sm-6">
          <button type="button" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
