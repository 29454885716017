import React from "react";
import { useState, useEffect } from "react";
import FrontBanner from "../Tools/FrontBanner";
import { FetchBanners, clearAction } from "../../Reducer/bannerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { Link } from "react-router-dom";
export default function Frontbanner() {
    const dispatch = useDispatch();
    const [banner, setBanner] = useState("");
    const bannersuccessData = useSelector((state)=> state.banner.success);
    const bannercontentData = useSelector((state)=> state.banner.content);
    useEffect(()=>{
        dispatch(FetchBanners({'home': '1'}))
    },[]);
    const imgurl = 'https://images.Indiafreestuff.in';
    useEffect(()=>{
        if(bannercontentData && bannersuccessData){
            setBanner(bannercontentData.slice(0,3));
            dispatch(clearAction());
        }
    },[bannersuccessData])
    return (
        <section class="catg">
            <div class="container">
                <div class="row">
                {/* <div class="col-sm-4 mb-3">
            <div class="set">
               <div class="row">
                  <div class="col-xl-5 col-xxl-6">
                  </div>
                  <div class="col-xl-7 col-xxl-6">
                     <div class="apimg">
                        <img src="assets/images/unnamed.png"/>
                     </div>
                     <h6>Deals On 35,000+ Styles</h6>
                     <h3>Starting @ Rs.199</h3>
                        <a class="btn" href="#">
                        <img src="assets/images/persent.png" alt="image"/> Flat 8% Off</a>
                  </div>
               </div>
            </div>
</div> */}              
                        {banner.length>0 && banner.map((ele)=>(
                            <>
                        <div class="col-sm-4 mb-3">
                        <Link to={ele.link} target="_blank">
                            <img class="proimg w-100" src={`${imgurl}/${ele.image}`} style={{height: '256px'}}/>
                        </Link>
                        </div>
                            </>
                        ))}
                     
                    {/* <FrontBanner></FrontBanner>
                    <FrontBanner></FrontBanner>
                    <FrontBanner></FrontBanner> */}
                </div>
            </div>
        </section>
    )
}