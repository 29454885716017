import React, { useEffect, useState } from "react"
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import { Link } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo.js";
import { FetchStaticContent, clearAction } from "../../../Reducer/staticconSlice.js";
import { useDispatch, useSelector } from "react-redux";

export default function TermsConditions(){
    const dispatch = useDispatch();
    const termsconcontent = useSelector((state)=> state.staticcontent.content);
    const termsconsuccess = useSelector((state)=> state.staticcontent.success);
    const [termscon, setTermscon] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let formdata = new FormData();
        formdata.append("slug", "Terms-and-Conditions");
        dispatch(FetchStaticContent(formdata))
    },[]);
    useEffect(()=>{
        if(termsconcontent && termsconsuccess){
            setTermscon(termsconcontent.content);
            setLoading(false);
        }
    },[termsconsuccess]);
    return(
        <>
        <section class="safety_tips">
        <div class="container">
        <h6><Link to="/">Home</Link>&lt; Terms & Condition </h6>
        {!loading && (
        <div dangerouslySetInnerHTML={{ __html: termscon }}></div>
        )
    }
        </div>
        </section>
        <Footerinfo/>
        </>
    )
}