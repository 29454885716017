import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStores, fetchFeaturedStores } from './api';
import { fetchbannerContent } from './api';
export const FetchBanners = createAsyncThunk('stores/fetchall', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchbannerContent(formData);
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const BannerSlice = createSlice({
    name: "banner",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
      featuredcontent: [],
      featuresuccess: false
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
        state.submitting = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(FetchBanners.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(FetchBanners.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(FetchBanners.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
      
    },
  });

export default BannerSlice.reducer;
export const { clearAction } = BannerSlice.actions;