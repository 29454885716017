import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getDealsCount, cleardealcountAction } from "../../../Reducer/dealcountSlice";
export default function DealTypes() {
    const dispatch = useDispatch();
    const dealscountcontent = useSelector((state)=> state.dealscount.content);
    const dealscountsuccess = useSelector((state)=> state.dealscount.success)
    const [dealcount, setDealCount] = useState({});
    const [loadingcount, setLoadingCount] = useState(true);
    useEffect(()=>{
        let currentDate = new Date();
        let unixTimestamp = Math.floor(currentDate.getTime() / 1000);
        dispatch(getDealsCount({timestamp: unixTimestamp}))
      },[]);

    useEffect(()=>{
        if(dealscountcontent && dealscountsuccess){
            setDealCount(dealscountcontent);
            dispatch(cleardealcountAction());
            setLoadingCount(false);
          }
    }, [dealscountcontent, dealscountsuccess]);
    return (
        <>
            <ul class="daily_ul">
               <li class="active"><Link to="/deals">Daily Deals 
               {!loadingcount && dealcount?.dailydeal !== 0 && (
                <>
                <span>{dealcount.dailydeal }</span>
                </>
              )
            }
               </Link></li>
               <li><Link to="/superdeals">Super Deals 
               {!loadingcount && dealcount?.superdeal !== 0 && (
                <>
                <span>{dealcount.superdeal }</span>
                </>
              )
            }
               </Link></li>
               <li><Link to="/favorites">Favorites</Link></li>
            </ul>
            </>
        
    )}