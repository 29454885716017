import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './Reducer/authSlice';
import registerReducer from './Reducer/Register';
import Login from './Reducer/Login';
import detailReducer from './Reducer/detailSlice';
import NewsArticleContentSlice from './Reducer/newsarticleSlice';
import staticconSlice from './Reducer/staticconSlice';
import SingleArticleSlice from './Reducer/singlearticleSlice';
import  ViewProfileSlice from './Reducer/viewprofileSlice';
import UpdateProfileSlice from './Reducer/updateprofileSlice';
import DealsContentSlice from './Reducer/dealsSlice';
import DealDetailSlice from './Reducer/dealDetailSlice';
import ChangePassSlice from './Reducer/changepassSlice';
import categoriesSlice from './Reducer/categoriesSlice';
import DealsByCategoriesSlice from './Reducer/dealsbyCategorySlice';
import LikeDislikeSlice from "./Reducer/likedislikeSlice";
import FavoritesSlice from './Reducer/favoriteSlice';
import commentSlice from './Reducer/commentSlice';
import storeSlice from './Reducer/storeSlice';
import RecentCommentsSlice from './Reducer/reccommentSlice';
import BannerSlice from './Reducer/bannerSlice';
import footerSlice from './Reducer/footerSlice';
import couponsSlice from './Reducer/couponsSlice';
import ProfileSlice from './Reducer/ProfileSlice';
import SimilardealsSlice from './Reducer/SimilardealsSlice';
import dealofdaySlice from './Reducer/dealofdaySlice';
import PrimeTimerSlice from './Reducer/primetimerSlice';
import contactusSlice from './Reducer/contactusSlice';
import dealcountSlice from './Reducer/dealcountSlice';
import redirectDealSlice from './Reducer/redirectSlice';
const rootReducer = combineReducers({
  auth: authReducer,
  register: registerReducer,
  detail: detailReducer,
  login: Login,
  articlecontent: NewsArticleContentSlice,
  singlearticlecontent: SingleArticleSlice,
  updateprofile: UpdateProfileSlice,
  dealofday: dealofdaySlice,
  dealscontent: DealsContentSlice,
  dealdetail: DealDetailSlice,
  changepass: ChangePassSlice,
  categories: categoriesSlice,
  dealsbycategories: DealsByCategoriesSlice,
  likedislike: LikeDislikeSlice,
  favoritedeals: FavoritesSlice,
  postcomments: commentSlice,
  staticcontent: staticconSlice,
  stores: storeSlice,
  recentcomments: RecentCommentsSlice ,
  banner: BannerSlice,
  footerdetails: footerSlice,
  coupons: couponsSlice,
  profile: ProfileSlice,
  similardeals: SimilardealsSlice,
  primetimercontent: PrimeTimerSlice,
  contactus: contactusSlice,
  dealscount: dealcountSlice,
  redirectdeal: redirectDealSlice
});

export default rootReducer;