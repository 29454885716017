import React from "react"
import { safetytipsdata } from "../../../data/SafetyTips/data"
export default function Tips({data}){
    return(
        <>
        <div class="tips">
         <h2>Safety Tips</h2>
         <div dangerouslySetInnerHTML={{ __html: data }}></div>
         {/* <h5>SAFETY TIPS</h5>
         <ul>
          {safetytipsdata.map((ele)=>{
            return(
                <>
                <li>
                {ele}
                </li>
                </>
            )
            })
          }
         </ul> */}
      </div>
        </>
    )
}