import { Link } from "react-router-dom";
export default function Coupon({data}){
    return(
        <>
        <div class="col-6 col-sm-6 col-xxl-3 mb-3">
                  <div class="set h-100">
                     <div class="hedset">
                        <img src={data.STOREIMAGE} alt="image"/>
                     </div>
                     <div class="img">
                     {data.EXPIRE == 1 && <span class="expired">Expired</span>}
                     {data.COUPON && data.COUPON.CODE ? 
          <>
                    <span class="apply">{data.COUPON.CODE}
                        <button
                        onClick={() => {
                            navigator.clipboard.writeText(data.COUPON.CODE);
                            alert('Coupon code copied: ' + data.COUPON.CODE);
                        }}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                        }}
                        >
                            <i class="fa fa-copy" style={{ color: '#fff', fontSize: '12px' }}></i>
                            </button>
                            </span>
                            </>
                            : "" }
                            <Link to={`/singleproduct/${data.SLUG}`}>
                        <img src={data.THUMBIMAGE} alt="img"/>
                             </Link>
                     </div>
                     <div class="content">
                     <Link to={`/singleproduct/${data.SLUG}`}>
                        <p>{data.TITLE}</p>
                    </Link>
                        {/* <h5>Rs. {data.ORIGNAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5> */}
                    {(data.ORIGNAL_PRICE !== '') ? (
                    <h5>Rs. {data.ORIGNAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5>
                ) : (
                    <h5><span>{data.DISCOUNT_PRICE}</span> </h5>
                )}

                        {/* {data.ORIGINAL_PRICE && !data.ORIGINAL_PRICE.isEmpty() ? (
                    <h5>Rs. {data.ORIGINAL_PRICE} <span>Rs. {data.DISCOUNT_PRICE}</span></h5>
                ) : (
                    <h5><span>{data.DISCOUNT_PRICE}</span> </h5>
                )} */}
                        <Link className="btn" to={`/ifsredirect/${data.ID}`} target="_blank">
                        Buy Now
                        </Link>
                     </div>
                  </div>
               </div>
        </>
    )
}