import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchStores, fetchFeaturedStores } from './api';

export const FetchStores = createAsyncThunk('stores/fetchall', async (_, { rejectWithValue }) => {
    try {
      const response = await fetchStores();
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  export const FetchFeaturedStores = createAsyncThunk('stores/fetchfeatured', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchFeaturedStores(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const StoreSlice = createSlice({
    name: "stores",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
      featuredcontent: [],
      featuresuccess: false
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
        state.featuresuccess = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(FetchStores.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(FetchStores.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(FetchStores.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        })
        .addCase(FetchFeaturedStores.pending, (state) => {
            state.submitting = true;
            state.error = null;
            state.featuresuccess = false;
          })
          .addCase(FetchFeaturedStores.fulfilled, (state, action) => {
            state.submitting = false;
            state.featuredcontent = action.payload;
            state.featuresuccess = true;
            state.error = null;
          })
          .addCase(FetchFeaturedStores.rejected, (state, action) => {
            state.submitting = false;
            state.error = action.payload;
            state.featuresuccess = false;
          });;
    },
  });

export default StoreSlice.reducer;
export const { clearAction } = StoreSlice.actions;