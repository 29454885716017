import React from "react";
import { Link } from "react-router-dom";
const Owlselecteditem = ({ data }) => {
    const filteredLabels = data.DEALLABEL.filter(label => label !== "SD" && label !== "Expired");
    
        return (  
            <div class="set h-100">
                <div class="hedset">
                    <img src={data.STOREIMAGE} alt="image" />
                </div>
                <Link to={`/singleproduct/${data.SLUG}`}>
                <div class="img">
                    <img src={data.THUMBIMAGE} alt="img" />
                </div>
                </Link>
                <div class="content">
                <Link to={`/singleproduct/${data.SLUG}`}>
                    <p>{data.TITLE}</p>
                </Link>
                <h5>
                    {(data.ORIGNAL_PRICE !== '') && (
                        <>
                    Rs. {data.ORIGNAL_PRICE} 
                    </>
                        )}
                        <span>
                         {!isNaN(Number(data.DISCOUNT_PRICE)) && data.DISCOUNT_PRICE !== '' ? (
                                <>
                                Rs. {data.DISCOUNT_PRICE}{" "}
                                </>
                            ) : (
                                <>
                                {data.DISCOUNT_PRICE}
                                </>
                            )}
                        
                        </span>
                        </h5>
                </div>
            </div>
        )
}
export default Owlselecteditem;