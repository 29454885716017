import { getComments, clearAction, likedislikeComment } from "../../../Reducer/commentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { postComment } from "../../../Reducer/commentSlice";
export default function Reply({data , id}){
    const dispatch = useDispatch();
    const [comments, setComments] = useState([]);
    const [message, setMessage] = useState("");
    const [ replyDisplay, setreplyDisplay] = useState(false);
    const username = localStorage.getItem('comment_username');
  const email = localStorage.getItem('comment_email');
  const [commentform, setCommentForm] = useState({
    name: "",
    email: "",
    message: ""
});
    const commentsuccessData = useSelector((state)=> state.postcomments.success);
    const commentcontentData = useSelector((state)=> state.postcomments.content);
    const commentlikesuccData = useSelector((state)=> state.postcomments.likesuccess);
    const commentaddsuccessData = useSelector(
      (state) => state.postcomments.addsuccess
    );
    const commentsinteracted = JSON.parse(localStorage.getItem('commentsinteracted'));
 

    useEffect(()=>{
        if(commentlikesuccData){
          dispatch(clearAction());
        }
    },[commentsuccessData, commentlikesuccData]);
    const handlereplytoggle = ()=> {
        setMessage('');
        setreplyDisplay(!replyDisplay);
    }
    const updateLocalStorage = (id) => {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      commentsInteracted.push(id);
      localStorage.setItem('commentsInteracted', JSON.stringify(commentsInteracted));
    };
    const handleChange = (event)=> {
      setMessage(event.target.value);
    }
    const likeCommentHandler = (id)=>{
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 1);
        formData.append('dislike', 0);
        formData.append('commentid', id);
        dispatch(likedislikeComment(formData));
        updateLocalStorage(id);
      }
    }
    const dislikeCommentHandler = (id) => {
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 0);
        formData.append('dislike', 1);
        formData.append('commentid', id);
      dispatch(likedislikeComment(formData));
      updateLocalStorage(id);
      }
    }
    const checkInteracted = (id)=> {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      if (commentsInteracted.includes(id)) {
        return true
      }
      else{
        return false
      }
    }
    const newreplyHandler = (commentid) => {
        localStorage.setItem('comment_username', commentform.name);
        localStorage.setItem('comment_email', commentform.email);
      let data = new FormData();
        data.append('comment_ID', commentid);
      data.append('comment_post_ID', id);
      data.append("author", commentform.name);
      data.append("email", commentform.email);
      data.append('comment',  commentform.message );
      setMessage('');
      dispatch(postComment(data));
      setCommentForm({
        name: "",
        email: "",
        message: "",
      });
    }
    const exisitingreplyHandler = (commentid)=>{
      let data = new FormData();
      data.append('comment_ID', commentid);
      data.append('comment_post_ID', id);
      data.append("author", username);
      data.append("email", email);
      data.append('comment',  commentform.message );
      setMessage('');
      dispatch(postComment(data));
      setCommentForm({
        name: "",
        email: "",
        message: "",
      });
      
    }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCommentForm({
          ...commentform,
          [name]: value,
        });
      };
    return(
        <>
        <li key={data.id}> 
                <img
                  className="rounded"
                  src="https://batrips.com/LAUNCH/assets/hunt_images/coool.png"
                  alt="profile_pic"
                />
                
                <h4>{data.author} <span className="float-end">{data.datetime}</span></h4>
                
                <p>{data.comment} <button className="reply" onClick={handlereplytoggle }>Reply <i class="fa-solid fa-reply"></i></button></p>
                <p><i className='me-3' 
                style={{ cursor: 'pointer', color: checkInteracted(data.id) ? 'grey' : 'inherit' }}
                >
                  <i class="fa-regular fa-thumbs-up" onClick={()=> {likeCommentHandler(data.id)}}></i>{data.like_comment}</i>
                <i style={{ cursor: 'pointer', color: checkInteracted(data.id) ? 'grey' : 'inherit' }}>
                <i class="far fa-thumbs-down" onClick={()=> {dislikeCommentHandler(data.id)}}></i>
                {data.dislike_comment}
                </i>
                {replyDisplay && (
                <div className="recomment">
                  <textarea className="form-control" placeholder="Reply..... " name="message" value={commentform.message}
              onChange={handleInputChange}></textarea>
                  {!username && !email ? (
                       <>
                       <div class="inputdiv">
                       <input
                         type="text"
                         placeholder="First Name"
                         class="form-control"
                         name="name"
                         value={commentform.name}
                         onChange={handleInputChange}
                       />
                       <input
                         type="text"
                         placeholder="Email Address"
                         class="form-control"
                         name="email"
                         value={commentform.email}
                         onChange={handleInputChange}
                       />
                     </div>
                     <>
                    <button onClick={()=> newreplyHandler(data.id)}>Submit</button>
                    <button className="cancel" onClick={handlereplytoggle }>Cancel</button>
                    </>
                     </>
                  ) : (
                    <>
                    <button onClick={()=> exisitingreplyHandler(data.id)}>Submit</button>
                    <button className="cancel" onClick={handlereplytoggle }>Cancel</button>
                    </>
                  )}
                 
                </div>
              )}
                </p>
                {/* <div className="recomment"> */}

                {data.children.map((item)=> (
                <Reply data={item} />
                ))}
               
              {/* </div> */}
             
                
                
               
              </li>
        
        </>
    )
}