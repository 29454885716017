import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction } from "../../../Reducer/dealsSlice.js";
import { FetchCoupons } from "../../../Reducer/couponsSlice.js";
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
import { fetchFavoritesDeals } from "../../../Reducer/favoriteSlice.js";
export default function CouponsPage() {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const couponsData = useSelector((state)=> state.coupons.content);
    const couponssuccessData = useSelector((state)=> state.coupons.success);
    const [coupons, setCoupons] = useState([]);
    const[loading , setLoading] = useState(true);
  const [favoritesdeals, setFavoritesDeals] = useState([]);
    const favoritedealsuccessData = useSelector(
      (state) => state.favoritedeals.success
    );
    const favoritedealContent = useSelector(
      (state) => state.favoritedeals.favoritedealcontent
    );
    const FavoriteAddsuccessData = useSelector(
      (state) => state.favoritedeals.addsuccess
    );
   const FavoriteRemovesuccessData = useSelector(
     (state) => state.favoritedeals.removesuccess
   );
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
    const handlePageChange = (page) => {
      if (page >= 1 && page <= 4) {
        setCurrentPage(page);
      }
    };
    useEffect(() => {
      if (userid) {
        let formData = {
          userid: userid,
        };
        dispatch(fetchFavoritesDeals(formData));
      }
    }, [userid, FavoriteAddsuccessData, FavoriteRemovesuccessData]);
    useEffect(()=>{
      setLoading(true);
        dispatch(FetchCoupons({
          page: currentPage,
          limit: 20
        }));
      
    },[currentPage]);
    useEffect(()=>{
      if (favoritedealsuccessData && favoritedealContent) {
        dispatch(clearAction());
            setFavoritesDeals(favoritedealContent);
            setLoading(false);
        }
    },[favoritedealsuccessData, favoritedealContent, FavoriteAddsuccessData,FavoriteRemovesuccessData ])
  useEffect(() => {
    if (couponsData && couponssuccessData) {
      console.log(couponsData, 'coupon');
      setCoupons(couponsData);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [couponssuccessData]);
  
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
      <div className="row">
      {loading ? (
        <LoadingOverlay /> 
      ) : (
        coupons.length > 0 &&
        coupons.map((ele) => {
          const favoriteDeal = favoritesdeals.find(fav => fav.deal.ID === ele.ID); 
              const isFav = Boolean(favoriteDeal);
              const favid = favoriteDeal ? favoriteDeal.id : null; 
          return <Dailydealifs deal={ele} isFav={isFav} key={ele.ID} favid={favid}/>; 
        })
      )}
    </div>
        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>
      </div>
      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
