import React, { useEffect, useState } from "react";
import Dailydealdayifs from "../../Tools/Dailydealdayifs";
import { fetchdealofdayContent } from "../../../Reducer/api";
import { getDealOfDayContent, clearAction } from "../../../Reducer/dealofdaySlice.js";
import { useDispatch, useSelector } from "react-redux";
import DealDay from "../../Home/Dealday.js";
export default function DayDeals() {
  const dispatch = useDispatch();
     const [dealContent, setDealContent] = useState([]);
     const [loading, setLoading] = useState(true);
  const dealContentsuccess = useSelector((state)=> state.dealofday.success);
  const dealContentdata = useSelector((state)=> state.dealofday.content);
  useEffect(()=>{
    if(dealContentdata && dealContentsuccess){
      setDealContent(dealContentdata);
      setLoading(false);
      dispatch(clearAction());
    }
  },[dealContentsuccess]);
  useEffect(()=>{
    dispatch(getDealOfDayContent())
  },[]);

    return (
      <>
        <div className="deals_shop">
          <h3>Deals Of The Day</h3>

                <DealDay/>
        </div>
      </>
    );
}
