import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { clearStatus, loginForm } from "../../../Reducer/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
import { changePasswordForm } from "../../../Reducer/changepassSlice";
import { forgotpassFormSubmit } from "../../../Reducer/api";
export default function ForgotPassword() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');

//   useEffect(() => {
//     const storedUser = localStorage.getItem("user");
//     const userObject = JSON.parse(storedUser);
//     setUserId(userObject.id);
//   }, []);

    const handleChange = (event) => {
   setEmail(event.target.value);
 };

  const handleSubmit = async (e) => {
      e.preventDefault();
      let formdata = new FormData();
      formdata.append('email', email);
      const response = await forgotpassFormSubmit(formdata);
      if (response.data.statusCode == '500') {
          let errorData = response.data.responseData.message;
           toast.error(errorData);
      }
      if (response.data.statusCode == '200') {
          let successData = response.data.responseData.message;
           toast.success(successData);
          setEmail("");
      }
    // if (
    //   validator.isLength(passwordchange.currentpassword, { min: 8 }) &&
    //   validator.isLength(passwordchange.newpassword, { min: 8 })
    // ) {
    //   let formdata = new FormData();
    //   formdata.append("userid", userId);
    //   formdata.append("current_password", passwordchange.currentpassword);
    //   formdata.append("new_password", passwordchange.newpassword);
    //   const action = await dispatch(changePasswordForm(formdata));
    //   const content = action.payload;
    //   if (content.statusCode == 200) {
    //     toast.success(content.responseData.message);
    //   } else {
    //     toast.error(content.responseData.message);
    //   }
    // } else {
    //   toast.error("Password must be atleast 8 character long");
    // }
  };
  return (
    <div
      class="modal fade"
      id="forgotpassword"
      tabIndex="-1"
      aria-labelledby="forgotpasswordModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered loginpage">
        <div class="modal-content">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div class="modal-body">
            <div class="form-signin">
              <form onSubmit={handleSubmit}>
                <h2>Forgot Password</h2>
                <div class="form-group">
                  <input
                    type="email"
                                      name="email"
                                      value={email}
                    onChange={handleChange}
                    class="form-control"
                    placeholder="Email"
                    required=""
                  />
                </div>
                <button class="btn btn-lg btn-warning btn-block" type="submit">
                  Send Password Reset Link
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
