import {
  likeDislikeDeal,
  clearAction,
} from "../../../Reducer/likedislikeSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Dislike({id}) {
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
  const dispatch = useDispatch();
  const DisLikesuccessData = useSelector((state) => state.likedislike.success);
  const DisLikefailureData = useSelector((state) => state.likedislike.failure);
  const DisLikeRespoData = useSelector((state) => state.likedislike.content);
  const onDisLikeClick = () => {
    if (userid) {
      let formData = new FormData();
      formData.append("userid", userid);
      formData.append("dealid", "2116510");
      formData.append("like", "0");
      formData.append("dislike", "1");
      dispatch(likeDislikeDeal(formData));
    } else {
      toast.success("login first");
    }
  };
  if (DisLikesuccessData) {
    if (DisLikeRespoData.status == "success") {
      toast.success(DisLikeRespoData.responseData.message);
      dispatch(clearAction());
    }
  }
  return (
    <>
      <a onClick={onDisLikeClick}>
        <i className="far fa-thumbs-down"></i>
      </a>
    </>
  );
}
