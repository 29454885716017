import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';
import { redirectDeal } from '../../../Reducer/redirectSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoadingOverlay from '../Loading';
const LoadingRedirect = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate(); 
  const redirectcontent = useSelector((state)=> state.redirectdeal.content);
  const redirectsuccessData = useSelector((state)=> state.redirectdeal.success);
  useEffect(() => {
    // Set a delay before redirecting to the target URL
    const timer = setTimeout(() => {
      console.log(id);
      if (id) {
        setIsLoading(true);
        let formData = new FormData();
        formData.append('dealid', id);
        dispatch(redirectDeal(formData));
      }
    }, 2000); 

    return () => clearTimeout(timer); 
  }, [id]);
  useEffect(()=>{
    if(redirectsuccessData && redirectcontent){
      console.log(redirectcontent.data);
      window.location.href = redirectcontent.data;
    }
  },[redirectcontent, redirectsuccessData]);
  return (
    <>
    
    <header class="header">
                                                <div class="container">
                                                  <nav class="navbar">
                                                    <a
                                                      class="navbar-brand"
                                                      href="/"
                                                    >
                                                      <img src="../assets/images/logo.png" />
                                                    </a>
                                                  </nav>
                                                </div>
                                              </header>
    <div>
      
      {isLoading ? (
         <div className="loading">
         <div className="rotating-loader"></div>
       </div>
      ) : (
        <div>Redirecting...</div>
      )}
                                            
    </div>
    </>
  );
};

export default LoadingRedirect;

