import { FetchStores, clearAction } from "../../../Reducer/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react"
import { FetchCategories, clearcatAction } from "../../../Reducer/categoriesSlice";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { getFilteredDeals } from "../../../Reducer/dealsSlice";
export default function Filters(){
    const dispatch = useDispatch();
    const [discount, setDiscount] = useState({ min: 0, max: 100 });
    const [selectedStores, setSelectedStores] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [price, setPrice] = useState({ min: 10, max: 100000 });
    const storecontentData = useSelector((state)=> state.stores.content);
    const storesuccessData = useSelector((state)=> state.stores.success);
    const categoryContent = useSelector((state)=> state.categories.content);
    const categorysuccessData = useSelector((state) => state.categories.success);
    const [storeSearchTerm, setStoreSearchTerm] = useState(''); 
    const [categorySearchTerm, setCategorySearchTerm] = useState('');
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [catLoading, setCatLoading] = useState(true);
    useEffect(()=>{
        dispatch(FetchCategories());
    }, [])
  useEffect(() => {
    if (categorysuccessData && categoryContent) {
      setCategories(categoryContent);
      setCatLoading(false);
      dispatch(clearcatAction());
    }
  }, [categorysuccessData, categoryContent]);
    useEffect(()=>{
        dispatch(FetchStores())
    },[]);
    useEffect(()=>{
        if(storecontentData && storesuccessData){
            setLoading(false);
            console.log(storecontentData, 'storecontent');
            setStores(storecontentData);
            dispatch(clearAction());
        }
    },[storesuccessData]);
 
    const handleStoreChange = (id) => {
        setSelectedStores((prevStores) =>
          prevStores.includes(id)
            ? prevStores.filter((storeId) => storeId !== id)
            : [...prevStores, id]
        );
      };
      const handleDiscountChange = (values) => {
        setDiscount({ min: values[0], max: values[1] });
    };
      const handleCategoryChange = (id) => {
        setSelectedCategories((prevCategories) =>
          prevCategories.includes(id)
            ? prevCategories.filter((categoryId) => categoryId !== id)
            : [...prevCategories, id]
        );
        console.log(selectedCategories);
      };
      const handlePriceChange = (values) => {
        setPrice({ min: values[0], max: values[1] });
    };
    const resetFilters = () => {
        setDiscount({ min: 0, max: 100 }); 
        setPrice({ min: 1, max: 100000 }); 
        setSelectedCategories([]); 
        setSelectedStores([]); 
        setStoreSearchTerm(''); 
        setCategorySearchTerm(''); 

    };
    const handleStoreSearch = (e) => {
        const searchTerm = e.target.value;
        console.log(searchTerm);
        setStoreSearchTerm(searchTerm); // Update store search term state
    };
    const handleCategorySearch = (e)=>{
        const searchTerm = e.target.value;
        setCategorySearchTerm(searchTerm);
    }
      const applyFilters = () => {
        const filtersData = {
            minamount: discount.min, 
            maxamount: discount.max,
            minprice: price.min, 
            maxprice: price.max,
            categories: selectedCategories,
            stores: selectedStores,
            page: 1,
            limit: 20,
        };
        let formData = new FormData();
        formData.append('stores', stores);
        formData.append('minamount', discount.min);
        formData.append('maxamount', discount.max);
        formData.append('categories', categories);
        formData.append('minprice', price.min);
        formData.append('maxprice', price.max)
        dispatch(getFilteredDeals(formData));
        console.log("Filters data to be sent to API:", filtersData);

        // Dispatch an action or API call with filter data here
        // dispatch(applyFiltersAPI(filtersData));
    };
    

    
    return(
        <>
         <div className="filter-section bg-light p-3">
                <h4>Filters</h4>
                <div className="d-flex justify-content-between mb-3">
                    <button className="btn btn-warning" onClick={resetFilters}>Reset</button>
                    <button className="btn btn-warning" onClick={applyFilters}>Apply</button>
                </div>
                <div className="row">
                    <div className="col-md-6">
                    <input
                    type="text"
                    className="form-control mb-2"
                    placeholder="Search stores..."
                    onChange={handleStoreSearch}
                />
                        <div
                            className="store-list overflow-auto"
                            style={{ maxHeight: "200px" }}
                        >
                             {!loading &&
                                stores.length > 0 &&
                                stores.filter((store) =>
                                    store.TITLE.toLowerCase().includes(storeSearchTerm.toLowerCase())
                                ).map((store) => (
                                <div className="form-check" key={store.ID}>
                                    <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`store${store.ID}`}
                                    onChange={() => handleStoreChange(store.ID)}
                                    checked={selectedStores.includes(store.ID)} 
                                    />
                                    <label className="form-check-label" htmlFor={`store${store.ID}`}>
                                    {store.TITLE}
                                    </label>
                                </div>
                                ))}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Search categories..."
                            onChange={handleCategorySearch}
                        />
                        <div
                            className="category-list overflow-auto"
                            style={{ maxHeight: "200px" }}
                        >
                           {!catLoading &&
                            categories.length > 0 &&
                            categories
                            .filter((category) =>
                                category.TITLE.toLowerCase().includes(categorySearchTerm.toLowerCase())
                            ).map((category) => (
                            <div className="form-check" key={category.ID}>
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id={`category${category.ID}`}
                                onChange={() => handleCategoryChange(category.ID)}
                                checked={selectedCategories.includes(category.ID)}
                                />
                                <label className="form-check-label" htmlFor={`category${category.ID}`}>
                                {category.TITLE}
                                </label>
                            </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                <div className="col-md-12">
                    <label htmlFor="discountRange" className="form-label">
                        Filter by Discount: <span>{discount.min}%</span> to <span>{discount.max}%</span>
                    </label>
                    <RangeSlider
                        id="discountRange"
                        min={0}
                        max={100}
                        value={[discount.min, discount.max]}
                        onInput={handleDiscountChange}
                        step={1}
                    />
                </div>
            </div>

 <div className="row mt-3">
                <div className="col-md-12">
                    <label htmlFor="priceRange" className="form-label">
                        Filter by Price: Rs. <span>{price.min}</span> to Rs. <span>{price.max}</span>
                    </label>
                    <RangeSlider
                        id="priceRange"
                        min={1}
                        max={100000}
                        value={[price.min, price.max]}
                        onInput={handlePriceChange}
                        step={1}
                    />
                </div>
            </div>
                <div className="row mt-3">
                    <div className="col-md-12">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="hideExpiredDeals"
                            />
                            <label
                                className="form-check-label"
                                htmlFor="hideExpiredDeals"
                            >
                                Hide Expired Deals
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}