import React from "react";
import Owlselecteditem from "../../Tools/Owlselecteditem";
import SimilarProductitem from "../../Tools/SimilarProduct/SimilarProductItem";
export default function MoreDeals({data}){
    return(
        <>
        <div class="row">
        {data.map((ele)=>(
        <div class="col-sm-6 col-xl-3 mb-3 col-6">
         
            <SimilarProductitem ele={ele}/>
        
        </div>
            ))}

            
        </div>
        </>

    )
}