import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction,  } from "../../../Reducer/dealsSlice.js";
import { getsearchedDeals } from "../../../Reducer/dealsSlice.js";
import { useSearchParams } from 'react-router-dom';
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
import { fetchFavoritesDeals } from "../../../Reducer/favoriteSlice.js";
export default function StoreDealsPage() {
    const {id} = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("search") || "");
    const dispatch = useDispatch();
    const dealscontent = useSelector((state) => state.dealscontent.content);
    const dealssuccessData = useSelector((state) => state.dealscontent.success);
    const [loading, setLoading] = useState(true);
    const [storedeals, setstoreDeals] = useState("");
    const [favoritesdeals, setFavoritesDeals] = useState([]);
    const favoritedealsuccessData = useSelector(
      (state) => state.favoritedeals.success
    );
    const favoritedealContent = useSelector(
      (state) => state.favoritedeals.favoritedealcontent
    );
    const FavoriteAddsuccessData = useSelector(
      (state) => state.favoritedeals.addsuccess
    );
   const FavoriteRemovesuccessData = useSelector(
     (state) => state.favoritedeals.removesuccess
   );
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;

    useEffect(() => {
        const search = searchParams.get("search") || "";
        setSearchQuery(search);
      }, [searchParams]);
    useEffect(() => {
      setLoading(true);
        let formData = {
            limit: 20,
            page: currentPage,
            store_id: id
          };
          dispatch(getDealsContent(formData));
          }, [id, currentPage]);
          useEffect(() => {
            if (userid) {
              let formData = {
                userid: userid,
              };
              dispatch(fetchFavoritesDeals(formData));
            }
          }, [userid, FavoriteAddsuccessData, FavoriteRemovesuccessData]);
  useEffect(() => {
    if (dealscontent && dealssuccessData) {
      setstoreDeals(dealscontent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealssuccessData]);
  useEffect(()=>{
    if (favoritedealsuccessData && favoritedealContent) {
      dispatch(clearAction());
          setFavoritesDeals(favoritedealContent);
          setLoading(false);
      }
  },[favoritedealsuccessData, favoritedealContent, FavoriteAddsuccessData,FavoriteRemovesuccessData ])
  const handlePageChange = (page) => {
    if (page >= 1 && page <= 4) {
      window.scrollTo({top: 0});
      setCurrentPage(page);
    }
  };
  
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">
          {loading ? (
            <LoadingOverlay /> 
          ): (
              storedeals.length > 0  &&
              storedeals.map((ele) => {
                const favoriteDeal = favoritesdeals.find(fav => fav.deal.ID === ele.ID); 
                const isFav = Boolean(favoriteDeal);
                const favid = favoriteDeal ? favoriteDeal.id : null; 
              return <Dailydealifs deal={ele} isFav={isFav} key={ele.ID} favid={favid}></Dailydealifs>
              })
            )
            }
        </div>
        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>

      </div>
      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
