import { fetchsimilardeals  } from "./api";
import { redirectdeals } from "./api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const redirectDeal = createAsyncThunk(
  "deals/redirectdeal",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await redirectdeals(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const redirectSlice = createSlice({
  name: "redirectdeal",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
      state.submitting = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(redirectDeal.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(redirectDeal.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(redirectDeal.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default redirectSlice.reducer;
export const { clearAction } = redirectSlice.actions;