import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchContent } from "./api";
export const FetchStaticContent = createAsyncThunk('page/content', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchContent(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const StaticContentSlice = createSlice({
    name: "staticcontent",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(FetchStaticContent.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(FetchStaticContent.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(FetchStaticContent.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });
export const {clearAction }= StaticContentSlice.actions;
  export default StaticContentSlice.reducer;