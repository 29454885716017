import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { newsarticleContent } from "./api";
import {singlenewsarticleContent} from './api';

export const FetchNewsArticleContent = createAsyncThunk('newsarticle/newsarticlecontent', async (_, { rejectWithValue }) => {
    try {
      const response = await newsarticleContent();
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const NewsArticleContentSlice = createSlice({
    name: "articlecontent",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(FetchNewsArticleContent.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(FetchNewsArticleContent.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(FetchNewsArticleContent.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });
//   export const SingleFetchNewsArticleContent = createAsyncThunk('singlenewsarticle/content', async (formData, { rejectWithValue }) => {
//     try {
//       const response = await singleFetchNewsArticleContent(formData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   });
export default NewsArticleContentSlice.reducer;
export const { clearAction } = NewsArticleContentSlice.actions;