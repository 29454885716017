export default function UserProfile({user}){
    return (
      <>
        <div className="user-mtop">
          {/* <img src="assets/images/profile_user.jpg" alt="search-u" /> */}
          <div className="test">
            <h3>
              <b> {user.name}</b>
            </h3>
          </div>
        </div>
        <ul>
          <li>
            <span>Name</span>{user.name}
          </li>
          <li>
            <span>Email</span>{user.email}
          </li>
          <li>
            <span>Phone</span>{user.phone}
          </li>
          <li>
            <span>Address</span>{user.address}
          </li>
        </ul>
      </>
    );
}