import React from "react";
import { OffersData } from "../../../data/Productdata/Productdata";


export default function ProductOffers(offers){
    return (
      <>
        <div
          class="img_single"
          dangerouslySetInnerHTML={{ __html: offers.offers }}
        >
        </div>
      </>
    );
}