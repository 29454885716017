import {ChangepasswordSubmit} from './api';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const changePasswordForm = createAsyncThunk('changePassword/changePasswordForm', async (formData, { rejectWithValue }) => {
    try {
        const response = await ChangepasswordSubmit(formData);
        return response.data;
       
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

const ChangePassSlice = createSlice({
  name: "changepass",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
          state.error = null;
          state.content = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordForm.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(changePasswordForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(changePasswordForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default ChangePassSlice.reducer;
export const { clearAction } = ChangePassSlice.actions;