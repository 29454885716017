import { likeDislikeDeal, clearAction} from "../../../Reducer/likedislikeSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Like(id) {
    const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
     const dispatch = useDispatch();
    const LikesuccessData = useSelector((state) => state.likedislike.success);
    const LikefailureData = useSelector((state) => state.likedislike.failure);
    const LikeRespoData = useSelector((state) => state.likedislike.content);
    const onLikeClick = () => {
        if (userid) {
            let formData = new FormData();
            formData.append("userid", userid);
            formData.append("dealid", "2116510");
            formData.append("like", "1");
            formData.append("dislike", "0");
            dispatch(likeDislikeDeal(formData));
        }
        else {
            toast.success('login first')
        }
        
    }
      if (LikesuccessData) {
        if (LikeRespoData.status == "success") {
          toast.success(LikeRespoData.responseData.message);
          dispatch(clearAction());
        }
      }
    return (
      <>
          <a onClick= {onLikeClick}>
            <i class="fa-regular fa-thumbs-up"></i>
          </a>
      </>
    );
}