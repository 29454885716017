import React from "react";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import SiteLinks from "./SiteLinks.js";
import Footerinfo from "../../Home/Footerinfo.js";
import { Link } from "react-router-dom";


export default function SiteMap(){
    return(
        <>
        <section class="safety_tips sitemap">
        <div class="container">
        <h6><Link to="/">Home</Link>&lt; Sitemap</h6>
        <SiteLinks/>
        </div>
        </section>
        <Footerinfo/>
        </>
    )
}