export const sitemaplinks = [
   {
    "src": "/",
    "text": "Home"
   },
   {
    "src": "/",
    "text": "Hot"
   },
   {
    "src": "/",
    "text": "Recharge"
   },
   {
    "src": "/",
    "text": "Stores"
   },
   {
    "src": "/",
    "text": "Freebies"
   },
   {
    "src": "/",
    "text": "forum"
   },
   {
    "src": "/",
    "text": "Earn"
   },

]