import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { detailFormSubmit } from './api';

export const detailForm = createAsyncThunk('detail/detailForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await detailFormSubmit(formData);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const detailSlice = createSlice({
  name: 'detail',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    detail:{},
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(detailForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(detailForm.fulfilled, (state,action) => {
        state.submitting = false;
        state.detail = action.payload;
        state.success=true;
        state.error = null;
      })
      .addCase(detailForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
  },
});
export default detailSlice.reducer;
