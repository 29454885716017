import React, { useEffect, useState } from 'react';
import { fetchPrimeDealsTimerContent, clearptAction } from '../../../Reducer/primetimerSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function Primesale({data}) {
    const dispatch = useDispatch();
    const primesaleContent = useSelector((state)=> state.primetimercontent.content);
    const primesalesuccessData = useSelector((state)=> state.primetimercontent.success);
    const [primesale, setPrimesale] = useState('');
    const [loading , setLoading] = useState(false);
    const [expired, setExpired] = useState(true);
    useEffect(()=>{
        dispatch(fetchPrimeDealsTimerContent())
    },[]);
    useEffect(() => {
        if(primesaleContent && primesalesuccessData){
            setPrimesale(primesaleContent[0].sale);
            dispatch(clearptAction());
        }
    }, [primesalesuccessData])
    useEffect(() => {
    }, [])

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    // const deadline = {ele.dealendson};
  
    const getTime = (deadline) => {
      const time = Date.parse(deadline) - Date.now();
      if(time>0){
        setExpired(false);
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
      }
      else{
        setExpired(true);
      }
    };
    useEffect(() => {
        if(primesale.dealstarton){
            const interval = setInterval(() => getTime(primesale.dealstarton), 1000);
        return () => clearInterval(interval);

        }
    
      }, [primesale]);
    
    return (
        <>
            {!loading && !expired && (
                <>
        <section class="Amazone_prime position-relative">

                <a href={primesale.dealstarturl} target="_blank">
                    <h6>{primesale.salestarttext}</h6>
                    </a>
                    <ul>
                        <li>{days} Days</li>
                        <li>{hours} Hours</li>
                        <li>{minutes} Minutes</li>
                        <li>{seconds} Seconds</li>
                    </ul>
        </section>

                    </>
            )}
            </>
           
    )

}