import React, { useEffect, useState, useRef} from "react";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import ProductInfo from "./ProductInfo.js";
import Form from "./Form.js";
import FeaturedProduct from "../../Home/FeaturedProduct.js";
import Footerinfo from "../../Home/Footerinfo.js";
import { getDealDetailContent } from '../../../Reducer/dealDetailSlice';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function SingleProduct() {
    const { slug } = useParams();
    const formRef = useRef(null);
    
    const scrollToForm = () => {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    };

  const username = localStorage.getItem('comment_username');
  const email = localStorage.getItem('comment_email');

  const dispatch = useDispatch();
    const productsuccessData = useSelector((state) => state.dealdetail.success);
    const productrespoData = useSelector((state) => state.dealdetail.content);
  const errorData = useSelector((state) => state.dealdetail.error);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState("");

  useEffect(() => {
    if (productsuccessData && productrespoData) {
      setProducts(productrespoData[0]);
      setLoading(false);
    }
  }, [productsuccessData]);

  useEffect(() => {
    setLoading(true);
    setProducts([]);
     const formData = {
       slug: slug,
     };
    
    dispatch(getDealDetailContent(formData));
  }, [slug]);
    return(
        <>
       <section class="singlepage">
        <div class="container">
            {!loading &&
            <>
              <ProductInfo product={products} onReplyClick={scrollToForm}/>
              <Form id={products.ID} ref={formRef} />
              </>
            }
            
      </div>
      </section>
      <Footerinfo></Footerinfo>
        </>
    )
}