import React, { useEffect, useState } from "react";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import Footerinfo from "../../Home/Footerinfo";
import DealDay from '../../Home/Dealday';
import { Link } from "react-router-dom";
import { fetchbannerContent } from '../../../Reducer/api';
import { useDispatch, useSelector } from "react-redux";
import { FetchBanners, clearAction } from "../../../Reducer/bannerSlice.js";
export default function Banner() {
    const [bannerContent, setBannerContent] = useState([]);
    const dispatch = useDispatch();
    const bannersuccessData = useSelector((state)=> state.banner.success);
    const bannercontentData = useSelector((state)=> state.banner.content);
    useEffect(()=>{
        dispatch(FetchBanners())
    },[]);
    useEffect(()=>{
        if(bannercontentData && bannersuccessData){
            setBannerContent(Object.values(bannercontentData));
            dispatch(clearAction());
        }
    },[bannersuccessData])
    return (
      <>
        <section className="safety_tips banner">
          <div className="container">
            <h6>
              <Link to="/">Home</Link> &lt; Banner
            </h6>
            <div className="row mt-4">
              <div className="col-lg-8 col-xxl-9 text-center">
                <h5>
                  Dear IFS User, Please login your telegram Account to get your
                  giftcard.
                </h5>
                <img
                  className="w-100 mb-5"
                  src="assets/images/banner.jpg"
                  alt="img"
                />
              </div>
              {bannerContent.length > 0 &&
                bannerContent.map((ele) => {
                  return (
                    <div
                      key={ele.id}
                      className="col-lg-8 col-xxl-9 text-center"
                    >
                      <a href={ele.link} target="_blank">
                        <h5>{ele.title}</h5>
                      </a>
                      <img
                        className="w-100 mb-5"
                        src={ele.image}
                        alt="Image"
                      />
                    </div>
                  );
                })}

              <div className="col-lg-4 col-xxl-3">
                <div className="deals_shop">
                  <h3>Deals Of The Day</h3>
                  <DealDay/>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footerinfo></Footerinfo>
      </>
    );
}