import React, { useEffect, useState } from "react"
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import { Link } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo.js";
import Store from "./Store.js";
import { FetchStores, clearAction } from "../../../Reducer/storeSlice"
import { useDispatch, useSelector } from "react-redux"
export default function Stores(){
    const dispatch = useDispatch();
    const storecontentData = useSelector((state)=> state.stores.content);
    const storesuccessData = useSelector((state)=> state.stores.success);
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        dispatch(FetchStores())
    },[]);
    useEffect(()=>{
        if(storecontentData && storesuccessData){
            setLoading(false);
            setStores(storecontentData);
            dispatch(clearAction());
        }
    },[storesuccessData]);
    return(
        <>
        <section class="safety_tips store">
            <div class="container">
            <h6><Link to="/">Home</Link>&lt;  Store </h6>
            {!loading && (
                <Store data={stores}/>
            )}
           
            </div>
        </section>
        <Footerinfo/>
        </>
    )
}