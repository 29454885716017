import React from "react";
import { Link } from "react-router-dom";
const Dailydealdayifs = ({ele}) => {
    return (
      <div class="st">
        <Link to={`/singleproduct/${ele.SLUG}`}>
        <div class="img">
          <img src={ele.THUMBIMAGE} alt="image" />
        </div>
        </Link>
        
        <p>
        <Link to={`/singleproduct/${ele.SLUG}`}>{ele.TITLE}
        </Link>
        </p>
       

        <h6>{ele.DATE}</h6>
      </div>
    );
}
export default Dailydealdayifs;