import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchrecentcomments,postnewComment } from "./api";
export const fetchRecentComments = createAsyncThunk(
  "comments/fetchrecentcomments",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchrecentcomments(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const RecentCommentsSlice = createSlice({
  name: "recentcomments",
  initialState: {
    submitting: false,
    error: null,
    success: false,
      content: [],
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase( fetchRecentComments.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase( fetchRecentComments.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase( fetchRecentComments.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default RecentCommentsSlice.reducer;
export const { clearAction } = RecentCommentsSlice.actions;
