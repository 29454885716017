import { NavLink, Navigate, useLocation } from 'react-router-dom';
import Pages from './Pages';
import Button from '../../Tools/Button';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { fetchFooterContent, clearAction } from '../../../Reducer/footerSlice';
import { useDispatch, useSelector } from "react-redux";
export default function Footer() {
   const dispatch = useDispatch();
   const [footerdata, setFooterdata] = useState({});
   const [loading ,setLoading] = useState(true);
   const footerContentData = useSelector((state)=> state.footerdetails.content);
   const footerSuccessData = useSelector((state)=> state.footerdetails.success);
   useEffect(()=>{
       dispatch(fetchFooterContent());
   },[]);
   useEffect(()=>{
       if(footerSuccessData && footerContentData){
           setFooterdata(footerContentData[0]);
           setLoading(false);
       }
   },[footerSuccessData])
    return (
    !loading && (
      <div><footer class="main_footer">
      <div class="container">
         <div class="row" dangerouslySetInnerHTML={{ __html: footerdata.daily_update }}>
            {/* <div class="col-md-6">
               <h3>Indiafreestuff is daily updated blog</h3>
               <p>For Indians which posts Free Samples, Online discounted deals, Free genuine Software License. Coupons, Contest and shopping tips & articles.
                  Our Mission: Be the leading Indian provider of high-quality, Working offers, Samples, Coupons, Contest & Articles To save money for our readers by providing good money saver deals, coupons, mobile recharge offers,  freebies & tips
               </p>
               <h3>Indiafreestuff – A Place for Free Coupons and Shopping Deals</h3>
               <p>Online shoppers have something in common. You all want to get the best products at the most affordable price. There are many ideas to get a cheaper price with the same quality. Aside from discounts, you can look for shopping deals or free coupons. Where can you get those deals? The answer is a site that offers many coupons and shopping specials like Indiafreestuff.in. Who are we? We give many free coupons that you can get for free. You only need to search the desired coupons on our site. Are we reliable? Of course we are. It means you are able to get low prices with our coupons.</p>
               <h3>Getting Promotional Coupons Online</h3>
               <p>We try to compete with other sites that offer promotional coupons and links for savings much money from online stores. We display various options of coupons that you can use after activation. These coupons are useful when you are going to make a transaction through shopping carts. You need to enter the codes before purchasing. Thus, you will get some discounts from the sellers. These coupon codes are used when you check out. We offer daily offer for you. These treats should be your weaponry to buy your desired products with more affordable price. Who provides those coupons? They are published by the sellers for the sake of promotion.</p>
            </div> */}
            {/* <div class="col-md-6">
               <h3>Where Can You Get Coupons & Loot Online Deals?</h3>
               <p>Making use of our coupon codes will give you lots of benefits. You are able to find great deals and earning price cuts. To get the best offers, you must search our products carefully. Don’t be a regular buyer who buys something when the demand arises. Getting some coupon codes beforehand will save much money in the future. You can use those coupons before the expiration date. Coupon codes don’t always in the form of discounts. We also provide coupons for free shipping and other deals. You need to read the details before purchasing the item.</p>
               <p>Coupon codes are useful and popular for both parties. For sellers, they are able to spread information and quality of their products. Coupon codes are popular strategies for marketing. These deals may attract more customers and increase revenue. For buyers, coupon codes are useful to get more affordable products on the internet. With current economy condition, people will look for cheaper products with the best qualities. They can save much money by using coupon codes. Actually, there’s an expiration date to use the coupons. You should use them before the due date.</p>
               <p>In the past, you will look for coupon codes from the newspaper. Today, you can get these coupons from the internet. Indiafreestuff is a reliable site to look for these coupons. After all, we have given many deals and coupons to our visitors. Most of our visitors look for discount coupons and free shipping coupons. Another benefit of using coupon codes is that you can buy whenever you want before expiring. With this, you can take your time to compare products before purchasing. You can sign up for newsletters in our site. It helps you to get the latest information about new coupons and deals. They are cheap and even free!</p>
            </div> */}
         </div>
         <div class="footer_bot">
            <div class="row">
               <div class="col-md-6 follow text-center">
                  <ul>
                     <li><Link to="/"><img src="../assets/images/icon1.png" alt="icon"/></Link></li>
                     <li><Link to="/dealsdetails/1422"><img src="../assets/images/icon2.png" alt="icon"/></Link></li>
                     <li><Link to="/dealsdetails/17"><img src="../assets/images/icon3.png" alt="icon"/></Link></li>
                     <li><Link to="/stores"><img src="../assets/images/icon4.png" alt="icon"/></Link></li>
                     <li><Link to="/dealsdetails/free-sample"><img src="../assets/images/icon5.png" alt="icon"/></Link></li>
                     <li><Link to="https://www.indiafreestuff.in/forum" target='_blank'><img src="../assets/images/icon6.png" alt="icon"/></Link></li>
                     <li><Link to="/deals"><img src="../assets/images/icon7.png" alt="icon"/></Link></li>
                  </ul>
               </div>
               <div class="col-md-6">
                  <form action={footerdata.newletter_action_url} method="post" >
                     <input class="form-control" type="text" name="email"/>
                     <button class="btn btn-outline-success" type="submit">Subscribe</button>
                  </form>
               </div>
               <div class="col-md-12 text-center">
                  <ul class="mnu">
                     <li>
                        <Link to={footerdata.pageslinks.home.link}> {footerdata.pageslinks.home.label} </Link></li>
                        <li><Link to={footerdata.pageslinks.aboutus.link}>{footerdata.pageslinks.aboutus.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.privacy.link}>{footerdata.pageslinks.privacy.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.terms.link}>{footerdata.pageslinks.terms.label}</Link></li>
                        <li><Link to= {footerdata.pageslinks.safety.link}>{footerdata.pageslinks.safety.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.contact.link}>{footerdata.pageslinks.contact.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.receivedstuff.link}>{footerdata.pageslinks.receivedstuff.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.faqs.link}>{footerdata.pageslinks.faqs.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.sitemap.link}>{footerdata.pageslinks.sitemap.label}</Link></li>
                        <li><Link to={footerdata.pageslinks.newsarticleslisting.link}>{footerdata.pageslinks.newsarticleslisting.label}</Link></li>
                       
{/* 
                     <li><a href="#">About us</a></li>
                     <li><a href="#">Privacy Policy</a></li>
                     <li><a href="#">Terms and Conditions</a></li>
                     <li><a href="#">Safety Tip</a></li>
                     <li><a href="#">Contact us</a></li>
                     <li><a href="#">Received Stuffs</a></li>
                     <li><a href="#">FAQs</a></li>
                     <li><a href="#">Sitemap</a></li>
                     <li><a href="#">rss-feed</a></li>
                     <li><a href="#">News & Articles</a></li> */}
                  </ul>
               </div>
            </div>
         </div>
      </div>
   </footer>
   <p class="text-center lastp">Copyright 2022 Powered by indiafreestuff. All Rights Reserved.</p>
 </div>
    )
  );
}