import React, { useEffect, useState } from "react";
import { fetchFooterContent, clearAction } from "../../Reducer/footerSlice";
import { useDispatch, useSelector } from "react-redux";
export default function Footerinfo() {
    const dispatch = useDispatch();
    const [footerdata, setFooterdata] = useState({});
    const [loading ,setLoading] = useState(true);
    const footerContentData = useSelector((state)=> state.footerdetails.content);
    const footerSuccessData = useSelector((state)=> state.footerdetails.success);
    useEffect(()=>{
        dispatch(fetchFooterContent());
    },[]);
    useEffect(()=>{
        if(footerSuccessData && footerContentData){
            setFooterdata(footerContentData[0]);
            setLoading(false);
        }
    },[footerSuccessData])
    return (<>
    {!loading && (
        <>
<section class="online_store">
    <div class="container">
        <div class="store_in">
            <div class="row">
                <div class="col-sm-3">
                    <div class="set">
                        <img src="../assets/images/shield.png" alt="icon" />
                        <h3>{footerdata.verifydeal?.value}</h3>
                        <p>{footerdata.verifydeal?.label}</p>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="set">
                        <img src="../assets/images/store.png" alt="icon" />
                        <h3>{footerdata.onlinestore?.value}</h3>
                        <p>{footerdata.onlinestore?.label}</p>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="set">
                        <img src="../assets/images/box.png" alt="icon" />
                        <h3>{footerdata.deallisted?.value}</h3>
                        <p>{footerdata.deallisted?.label}</p>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="set">
                        <img src="../assets/images/affection.png" alt="icon" />
                        <h3>{footerdata.happymembers?.value}</h3>
                        <p>{footerdata.happymembers?.label}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )}

    </>)
}
