import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {singlenewsarticleContent} from './api';
 
 export const SingleArticleContent = createAsyncThunk('singlenewsarticle/content', async (formData, { rejectWithValue }) => {
    try {
      const response = await singlenewsarticleContent(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

  const SingleArticleSlice = createSlice({
    name: "singlearticlecontent",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(SingleArticleContent.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(SingleArticleContent.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(SingleArticleContent.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });

  export default SingleArticleSlice.reducer;
export const { clearAction } = SingleArticleSlice.actions;
