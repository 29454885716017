import { useDispatch, useSelector } from "react-redux";
import { ContactusSubmit, clearcontactAction, FetchContactUs } from "../../../Reducer/contactusSlice";
import { useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function ContactusPage(){
    const dispatch = useDispatch();
    const contactsuccessData = useSelector((state)=> state.contactus.success);
    const contactsubsuccess = useSelector((state)=> state.contactus.subsuccess);
    const contacterrorData = useSelector((state)=> state.contactus.error);
    const contactcontentData = useSelector((state)=> state.contactus.content);
    const [contactlinks, setContactLinks] = useState("");

    useEffect(()=>{
        dispatch(FetchContactUs())
    },[])
    useEffect(()=>{
        if(contactsuccessData){
            setContactLinks(contactcontentData);
            dispatch(clearcontactAction());
        }
        if(contactsubsuccess){
            toast.success('Form submitted successfully');
            dispatch(clearcontactAction());
        }
        if(contacterrorData){
            dispatch(clearcontactAction());
        }
    },[contactsuccessData,contactsubsuccess, contacterrorData])
    const validationSchema = Yup.object({
        firstname: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('First Name is Required'),
        lastname: Yup.string()
          .max(20, 'Must be 20 characters or less')
          .required('Last Name is Required'),
        email: Yup.string()
          .email('Invalid email address')
          .required('Email Address is Required'),
        mobile: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, 'Must be at least 10 digits')
          .max(15, 'Must be 15 digits or less')
          .required('Mobile number is Required'),
        message: Yup.string()
          .max(200, 'Must be 200 characters or less')
          .required('Message is Required')
      });
    const formik = useFormik({
        initialValues: {
            firstname:'',
            lastname:'',
            email: '',
            mobile: '',
            message: ''

        },
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            let data = new FormData();
            for(let key in values){
                data.append(key, values[key]);
            }
            dispatch(ContactusSubmit(data));
          resetForm();
        },
      });

    return(
        <>
        <section class="heding" style={{ backgroundImage: 'url(/images/program_banner.png)'}}>
      <h2>Contact Us</h2>
</section>

<section class=" contact_us">
    <div class="container">
        <h2>Have Some Question</h2>
        <div class="contact_main">
            <div class="row">
                <div class="col-sm-8">
                    <form class="form-signin" onSubmit={formik.handleSubmit}>
                        <h3>Your Inquiry</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <input 
                                type="text" 
                                class="form-control" 
                                placeholder="First Name"
                                name="firstname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstname}
                                />
                                {formik.touched.firstname && formik.errors.firstname ? (
                                <div className="invalid-feedback" style={{ display: 'block', color: 'red' }}>{formik.errors.firstname}</div>
                            ) : null}
                            </div>
                            <div class="col-sm-6">
                                <input 
                                type="text" 
                                class="form-control" 
                                placeholder="Last Name"
                                name="lastname"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastname}
                                />
                                {formik.touched.lastname && formik.errors.lastname ? (
                                <div className="invalid-feedback" style={{ display: 'block', color: 'red' }}>{formik.errors.lastname}</div>
                            ) : null}
                            </div>
                            <div class="col-sm-12">
                                <input 
                                type="email" 
                                class="form-control" 
                                placeholder="Email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                <div className="invalid-feedback" style={{ display: 'block', color: 'red' }}>{formik.errors.email}</div>
                            ) : null}
                            </div>
                            <div class="col-sm-12">
                                <input 
                                type="text" 
                                class="form-control" 
                                placeholder="Mobile"
                                name="mobile"
                                onChange={formik.handleChange}
                                value={formik.values.mobile}
                                onBlur={formik.handleBlur}
                                />
                                 {formik.touched.mobile && formik.errors.mobile ? (
                                <div className="invalid-feedback" style={{ display: 'block', color: 'red' }}>{formik.errors.mobile}</div>
                            ) : null}
                            </div>
                            <div class="col-sm-12">
                                <textarea 
                                placeholder="Write your message..."
                                name="message"
                                onChange={formik.handleChange}
                                value={formik.values.message}
                                onBlur={formik.handleBlur}
                                
                                ></textarea>
                                 {formik.touched.message && formik.errors.message ? (
                                <div className="invalid-feedback" style={{ display: 'block', color: 'red' }}>{formik.errors.message}</div>
                            ) : null}
                            </div>
                            <div class="col-sm-12 ">
                                <button class="btn wow zoomIn" data-wow-delay=".2" type="submit">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-sm-4">
                    <div class="contact_right">
                        <h2>Request a Call Back</h2>
                        <p>We are here to help you, email us and we will get back to you.</p>
                        {/* <h6><span><i class="fas fa-map-marker-alt"></i></span> {contactlinks.full_address}</h6> */}
                        {/* <h6><span><i class="fas fa-phone-alt"></i></span> <a href="tel:12364464654">12364464654</a></h6> */}
                        <h6><span><i class="far fa-envelope"></i></span> <a
                                href={`mailto:${contactlinks.email}`}>{contactlinks.email}</a></h6>
                        <ul>
                            <li><a href= {contactlinks.facebook} target="_blank"><i class="fab fa-facebook-f"></i></a>
                            </li>
                            <li><a href={contactlinks.twitter}><i class="fab fa-twitter"></i></a></li>
                            <li><a href={contactlinks.google}><i
                                        class="fab fa-instagram"></i></a></li>
                            <li><a href={contactlinks.linkedin}><i class="fab fa-linkedin-in"></i></a></li>
                            {/* <li><a href={contactlinks.google}><i class="fab fa-google-plus-g"></i></a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
}