import { getComments, clearAction, likedislikeComment } from "../../../Reducer/commentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import SingleComment from "./SingleComment";
export default function NewsComments({id}){
    const dispatch = useDispatch();
    const [comments, setComments] = useState([]);
    const commentsuccessData = useSelector((state)=> state.postcomments.success);
    const commentcontentData = useSelector((state)=> state.postcomments.content);
    const commentlikesuccData = useSelector((state)=> state.postcomments.likesuccess);
    const commentaddsuccessData = useSelector(
      (state) => state.postcomments.addsuccess
    );
    const nestComments = (comments) => {
      const commentMap = {};
      
      comments.forEach(comment => commentMap[comment.id] = {...comment, children: []});
      
      const nestedComments = [];
    
      comments.forEach(comment => {
        if (comment.parent_id !== 0) {
          commentMap[comment.parent_id].children.push(commentMap[comment.id]);
        } else {
          nestedComments.push(commentMap[comment.id]);
        }
      });
    
      return nestedComments;
    };
    const commentsinteracted = JSON.parse(localStorage.getItem('commentsinteracted'));
    useEffect(()=>{
        if(id){
            let formData = new FormData();
            formData.append('news_id', id);
            dispatch(getComments(formData))
        }
    },[id,commentlikesuccData, commentaddsuccessData ]);
  
    useEffect(()=>{
      if(commentsuccessData && commentcontentData){
          setComments(nestComments(commentcontentData));
          dispatch(clearAction());
      }
      if(commentlikesuccData){
        dispatch(clearAction());
      }
  },[commentsuccessData, commentlikesuccData]);


    const updateLocalStorage = (id) => {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      commentsInteracted.push(id);
      localStorage.setItem('commentsInteracted', JSON.stringify(commentsInteracted));
    };
    const likeCommentHandler = (id)=>{
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 1);
        formData.append('dislike', 0);
        formData.append('commentid', id);
      dispatch(likedislikeComment(formData));
      updateLocalStorage(id);
      }
    }
    const dislikeCommentHandler = (id) => {
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 0);
        formData.append('dislike', 1);
        formData.append('commentid', id);
      dispatch(likedislikeComment(formData));
      updateLocalStorage(id);
      }
    }
    const checkInteracted = (id)=> {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      if (commentsInteracted.includes(id)) {
        return true
      }
      else{
        return false
      }
    }
    return(
        <>
           {comments.length > 0 && (
        <div className="details-comments">
          <h3>Comments</h3>
          <ul>
            {comments.map((ele) => (
                <>
              <SingleComment data={ele} id={id}/>
            
             </>
            ))}
          </ul>
        </div>
      )}
        
        </>
    )
}