import React, { useEffect, useState } from 'react';
import { fetchPrimeDealsTimerContent, clearptAction } from '../../../Reducer/primetimerSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function Primedeal({data}) {
    const dispatch = useDispatch();
    const notificationContent = useSelector((state)=> state.primetimercontent.content);
    const notificationsuccessData = useSelector((state)=> state.primetimercontent.success);
    const [notification, setNotification] = useState('');
    const [loading , setLoading] = useState(false);
    useEffect(()=>{
        dispatch(fetchPrimeDealsTimerContent())
    },[]);
    useEffect(() => {
        if(notificationContent && notificationsuccessData){
            setNotification(notificationContent[0].notifications);
            dispatch(clearptAction());
        }
    }, [notificationsuccessData])

    return (
        <section class="prime">
            {!loading && (
            <div dangerouslySetInnerHTML={{ __html: notification.notification_msg }}></div>
            )}
            {/* <h6>Prime Early Access Deals Live Now <a class="btn" href="#">Click Here</a></h6> */}
        </section>
    )

}