import React from 'react';

const NameEmailModal = ({
  show,
  onClose,
  userId,       
  userData,   
  handleSubmit, // Handle form submission
  handleInputChange, // Handle form input changes
}) => {
  return (
    <div
      className={`modal  ${show ? 'fade show' : ''}`}
      style={{ display: show ? 'block' : 'none' }}
      id="exampleModalsign"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered loginpage">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={onClose} 
          ></button>
          <div className="modal-body p-0">
            <div className="form-signin">
              <form onSubmit={handleSubmit}>
                <h2>Complete Your Profile</h2>
                {/* Displaying the user ID */}
                <p>User ID: {userId}</p>
                
                <div className="form-group">
                  <label htmlFor="name">Name:</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={userData.name}  // Bind the input to userData
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email:</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={userData.email} // Bind the input to userData
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <button className="btn btn-lg btn-warning btn-block" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameEmailModal;
