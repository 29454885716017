import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {fetchfootercontent} from './api';
 
 export const fetchFooterContent = createAsyncThunk('footerdetails/content', async (_, { rejectWithValue }) => {
    try {
      const response = await fetchfootercontent();
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

  const FooterSlice = createSlice({
    name: "footerdetails",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchFooterContent.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(fetchFooterContent.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(fetchFooterContent.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });

  export default FooterSlice.reducer;
export const { clearAction } = FooterSlice.actions;
