import { Link } from "react-router-dom";
import Like from "../Like";
import FavoriteIcon from "../FavoriteIcon";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addToFavorites,
  clearAction,
  removeFromFavorites
} from "../../../Reducer/favoriteSlice";
import Dislike from "../Dislike";
import { update } from "firebase/database";
const Dailydealifs = ({ deal, isFav, favid }) => {
  // console.log(btoa(deal.ID), 'deald');
  const dispatch = useDispatch();
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
  const [isFavorite, setIsFavorite] = useState(false);
      const checkSD = () => {
        return deal.DEALLABEL.includes('SD');
    };
    const filteredLabels = deal.DEALLABEL ? deal.DEALLABEL.filter(label => label !== "SD" && label !== "Expired") : [];
    const FavoritesuccessData = useSelector(
      (state) => state.favoritedeals.addsuccess
    );
   const FavoriteRemovesuccessData = useSelector(
     (state) => state.favoritedeals.removesuccess
   );
    const favoritedealContent = useSelector(
      (state) => state.favoritedeals.favoritedealcontent
    );
    const FavoritefailureData = useSelector(
      (state) => state.favoritedeals.error
    );

    const FavoriteRespoData = useSelector(
      (state) => state.favoritedeals.content
    );
    const updateLocalStorage = (id) => {
      const favdeals = JSON.parse(localStorage.getItem('favdeals')) || [];
      favdeals.push(id);
      localStorage.setItem('favdeals', JSON.stringify(favdeals));
    };

  const onFavoriteClick = async (dealId) => {
    if (userid) {
      if (isFav) {
        let formData = {
          favids: favid
        }
        await dispatch(removeFromFavorites(formData));
      }
      else {
        let formData = new FormData();
        formData.append("userid", userid);
        formData.append("dealid", dealId);
        await dispatch(addToFavorites(formData));
      }
         
      }
    else {
      // updateLocalStorage(dealId);
      toast('Please login first');
     }
       
    };
      if (FavoritesuccessData && FavoriteRespoData) {
        if (FavoriteRespoData.status == "success") {
          toast.success(FavoriteRespoData.responseData.message);
          dispatch(clearAction());
        } else {
          toast.error(FavoriteRespoData.responseData.message);
          dispatch(clearAction());
        }
      }
      if (FavoriteRemovesuccessData && FavoriteRespoData) {
        if (FavoriteRespoData.status == "success") {
          toast.success(FavoriteRespoData.responseData.message);
          setIsFavorite(false);
          dispatch(clearAction());
        } else {
          toast.error(FavoriteRespoData.responseData.message);
          dispatch(clearAction());
        }
      }
    // useEffect(() => {
    //   if (FavoritesuccessData && FavoriteRespoData) {
    //     if (FavoriteRespoData.status == "success") {
    //       toast.success(FavoriteRespoData.responseData.message);
    //       setIsFavorite(true);
    //       dispatch(clearAction());
    //     } else {
    //       toast.error(FavoriteRespoData.responseData.message);
    //       dispatch(clearAction());
    //     }
    //   }
    // }, [FavoritesuccessData, FavoriteRespoData]);

    
    return (
      <div class="col-6 col-sm-4 col-xl-4 col-xxl-3  mb-3">
        <div class="set h-100">
          <div class="hedset">
            <img src={deal.STOREIMAGE} alt="image" />
          </div>
          <div class="img">
           {filteredLabels.length > 0 && (
                        <span className="apply">{filteredLabels.join(', ')}</span>
                    )}
          {deal.COUPON && deal.COUPON.CODE ? 
          <>
          <span class="coupon-code">{deal.COUPON.CODE}
          <button
          onClick={() => {
            navigator.clipboard.writeText(deal.COUPON.CODE);
          }}
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
          }}
        >
              <i class="fa fa-copy" style={{ color: '#fff', fontSize: '12px' }}></i>
              </button>
              </span>
            </>
            : ""
        }
            {deal.SUPERDEALS == 1 ? <span class="sd">SD</span> : ''}
            {deal.EXPIRE == 1 && <span class="expired">Expired</span>}
            <img src={deal.THUMBIMAGE} alt="img" />
            <ul>
              <li>
                <a onClick={() => onFavoriteClick(deal.ID)}>
                  <i
                    class="fa-regular fa-heart"
                    style={{ color: isFav ? "red" : "black" }}
                  ></i>
                </a>
              </li>
              <li>
                <Like id={deal.ID} />
              </li>
              <li>
                <Dislike id={deal.ID}/>
              </li>
              <li>
                <a href="#">
                  <i class="fa-regular fa-message"></i>
                </a>
              </li>
            </ul>
          </div>
          {/* <h4>Brand: Sjyeza</h4> */}
          <div class="content">
        <Link to={`/singleproduct/${deal.SLUG}`}>
            <p>{deal.TITLE}</p>
            </Link>
            <h5>
            {(deal.ORIGNAL_PRICE !== '') && (
                        <>
                    Rs. {deal.ORIGNAL_PRICE} 
                    </>
            )}
            <span>
            {!isNaN(Number(deal.DISCOUNT_PRICE)) && deal.DISCOUNT_PRICE !== '' ? (
                                <>
                                Rs. {deal.DISCOUNT_PRICE}{" "}
                                </>
                            ) : (
                                <>
                                {deal.DISCOUNT_PRICE}
                                </>
                            )}
            </span>
              {/* <span>Rs. {deal.DISCOUNT_PRICE}</span> */}
              {deal.DISCOUNT_PERCENTAGE !== 0 && (
                <>
              <span class="per">({deal.DISCOUNT_PERCENTAGE}%Off)</span>
                </>
              )}
            </h5>
            {/* <a class="btn" href="#">Get Deal</a> */}
            <Link className="btn" to={`/ifsredirect/${deal.ID}`} target="_blank">
              Buy Now
            </Link>
          </div>
        </div>
      </div>

    );
}
export default Dailydealifs;