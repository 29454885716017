import React, {useEffect, useState} from "react";
import Owlifslist from "../Tools/Owlifslist";
import { FetchFeaturedStores, clearAction } from "../../Reducer/storeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
export default function FeaturedStores() {
    const dispatch = useDispatch();
    const featuredstorecontentData = useSelector((state)=> state.stores.featuredcontent);
    const featuredstoresuccessData = useSelector((state)=> state.stores.featuresuccess);
    const [featurestores, setfeatureStores] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        dispatch(FetchFeaturedStores({featured: 1}))
    },[]);
    useEffect(()=>{
        if(featuredstorecontentData && featuredstoresuccessData){
            setLoading(false);
            setfeatureStores(featuredstorecontentData);
            dispatch(clearAction());
        }
    },[featuredstoresuccessData]);
    const options = {
        loop: false,
        autoplay: false,
        autoplayTimeout: 5000,
        margin: 10,
        responsiveClass: true, 
        responsive: {
          0: { items: 2, nav: true },
                   576: { items: 3, nav: true },
                   768: { items:  4, nav: true },
                   992: { items: 5, nav: true },
                   1200: { items: 6, nav: true },
                   1400: { items: 7, nav: true },
                   1600: { items: 8, nav: true},
        }
      };
    return (<>
    {!loading && (
        <section class="featured_stores">
            <div class="container">
                <h2>Featured Stores</h2>
               
                    {/* <div class="owl-carousel featured_carousel owl.carousel.min owl-theme" id="myTab" role="tablist">
                    <OwlCarousel className='owl-theme featured_carousel' {...options}>
                    {!loading && featurestores.map((ele) => (
                    <React.Fragment key={ele.id}>
                        <Owlifslist data={ele} />
                    </React.Fragment>
                    
                ))}
                </OwlCarousel>; */}
                <Swiper
                className="featured_carousel"
          spaceBetween={20}
          modules={[Navigation, Scrollbar, A11y]}
          // loop={true}
          navigation={{
            prevEl: '.custom-prev-pr',
            nextEl: '.custom-next-nx',
          }}
          slidesPerView={1}
          breakpoints={{
            576: { slidesPerView: 3 },
            768: { slidesPerView: 4 },
            992: { slidesPerView: 5 },
            1200: {slidesPerView: 6},
            1400: { slidesPerView: 7 },
            1600: { slidesPerView: 8},
          }}
        >
                        {!loading && featurestores.map((ele) => (
                            <SwiperSlide key={ele.ID}>
                                                <Owlifslist data={ele} />
                                                </SwiperSlide>
                                            
                                        ))}
                </Swiper>
                <div className="custom-navigation">
        <button className="custom-prev-pr">
          &#10094; {/* Left Arrow Icon */}
        </button>
        <button className="custom-next-nx">
          &#10095; {/* Right Arrow Icon */}
        </button>
      </div>
                </div>
                
        </section>
    )}
        

    </>)
}

// $('.featured_carousel').owlCarousel({
//     loop: false,
//     autoplay: false,
//     autoplayTimeout: 5000,
//     margin: 10,
//     responsiveClass: true, 
//     responsive: {
//       0: { items: 2, nav: true },
//                576: { items: 3, nav: true },
//                768: { items:  4, nav: true },
//                992: { items: 5, nav: true },
//                1200: { items: 6, nav: true },
//                1400: { items: 7, nav: true },
//                1600: { items: 8, nav: true},
//     }
//   });   