import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { submitLoginForm, registerFormSubmit } from './api';

export const loginForm = createAsyncThunk('login/loginForm', async (formData, { rejectWithValue }) => {
    try {
        const response = await submitLoginForm(formData);
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('token', response.token);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});

export const registerForm = createAsyncThunk('register/registerForm', async (formData, { rejectWithValue }) => {
    try {
        const response = await registerFormSubmit(formData);
        // localStorage.setItem('user', JSON.stringify(response.data));
        // localStorage.setItem('token', response.token);
        return response;
    } catch (error) {
        return rejectWithValue(error.response);
    }
});



const registerSlice = createSlice({
    name: 'register',
    initialState: {
        submitting: false,
        error: null,
        success: false,
        language: '',
        auth: {},
        token: ""
    },
    reducers: {
        setUser: (state, action) => {
            state.auth = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        clearUser: state => {
            state.auth = null;
            state.success = false;
            localStorage.removeItem('user');
        },
        clearToken: state => {
            state.token = null;
            state.success = false;
            localStorage.removeItem('token');
        },
        changeLanguage: (state, action) => {
            state.language = action.payload;
            localStorage.setItem('cl', action.payload);
        },
        clearStatus: state => {
            state.success = false;
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loginForm.pending, state => {
                state.submitting = true;
                state.error = null;
                state.success = false;
            })
            .addCase(loginForm.fulfilled, (state, action) => {
                state.submitting = false;
                state.auth = action.payload.data;
                state.token = action.payload.token;
                state.success = true;
                state.error = null;
            })
            .addCase(loginForm.rejected, (state, action) => {
                state.submitting = false;
                state.error = action.payload;
                state.success = false;
            })
            .addCase(registerForm.pending, state => {
                state.submitting = true;
                state.error = null;
                state.success = false;
            })
            .addCase(registerForm.fulfilled, (state, action) => {
                state.submitting = false;
                state.auth = action.payload.data;
                state.token = action.payload.token;
                state.success = true;
                state.error = null;
            })
            .addCase(registerForm.rejected, (state, action) => {
                state.submitting = false;
                state.error = action.payload;
                state.success = false;
            });
    },
});
export const { setUser, setToken, clearStatus, clearUser, clearToken, changeLanguage } = registerSlice.actions;
export default registerSlice.reducer;

