import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import ModalButton from "../../Tools/ModalButton";
import ListItem from '../../Tools/ListItem';
import { headerData } from '../../../data/content';
import { changeLanguage, clearToken, clearUser } from '../../../Reducer/authSlice';
import Sidebarview from '../Sidebar/Sidebarview';
import Login from '../Btmodal/login';
import Signup from '../Btmodal/signup';
import ChangePassword from '../Btmodal/changepassword';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import EditProfile from '../Btmodal/profile';
import ForgotPassword from '../Btmodal/forgotpassword';
import Primesale from '../Deal/Primesale';
import Primedeal from '../Deal/Primedeal';

export default function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(state => state.auth.token);
    const datalist = headerData;
    const [isActive, setIsActive] = useState(false);
    const [isUser, setIsUser] = useState(false);
    const user = localStorage.getItem('user');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const history = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const username = JSON.parse(localStorage.getItem("user"))?.name || null;
  // useEffect(()=>{
  //   if(!showSignupModal && !showLoginModal){
  // const backdrop = document.querySelector('.modal-backdrop');
  //   if (backdrop) {
  //     backdrop.remove();
  //   }
  //   }
  // },[showSignupModal, showLoginModal])
  const openLoginModal = () => {
    setShowLoginModal(true);

  };
  
  const closeLoginModal = () => {
    setShowLoginModal(false);
    removeBackdrop();
  
  };
  
  const openSignupModal = () => {
    setShowSignupModal(true);

  };
  
  const closeSignupModal = () => {
    setShowSignupModal(false);
    removeBackdrop();
  };
  const removeBackdrop = () => {
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim() !== '') {
      navigate(`/deals?search=${encodeURIComponent(searchQuery)}`);
    }
  };
        useEffect(() => {
           if(!localStorage.getItem('user')){
            setIsUser(false);
           }else{
            closeLoginModal();
            setIsUser(true);
           }
        }, [user]);

    const handlelogout = () => {
        dispatch(clearUser());
        dispatch(clearToken());
        navigate('/');
        localStorage.removeItem('comment_username');
        localStorage.removeItem('comment_message');
        localStorage.removeItem('comment_email');
        toast('User logged out successfully');
    }


    const activesidebar = () => {
        setIsActive(current => !current);
    };
                                          return (
                                            <>
                                              <header class="header">
                                                <div class="container">
                                                  <nav class="navbar">
                                                    <span
                                                      className={
                                                        isActive
                                                          ? "bg-salmon openbtn"
                                                          : "openbtn"
                                                      }
                                                      onClick={activesidebar}
                                                    >
                                                      <i class="fa fa-bars"></i>
                                                    </span>
                                                    <a
                                                      class="navbar-brand"
                                                      href="/"
                                                    >
                                                      <img src="../assets/images/logo.png" />
                                                    </a>
                                                    <button class="search d-none">
                                                      <i class="fa-solid fa-magnifying-glass"></i>
                                                    </button>
                                                    <form class="navbar-text" onSubmit={handleSearch}>
                                                      <div class="form-group">
                                                        <i class="bi bi-search"></i>
                                                        <input
                                                          type="text"
                                                          name="email"
                                                          class="form-control"
                                                          placeholder="Search For Your Favorite Brands"
                                                          required=""
                                                          value={searchQuery}
                                                          onChange={(e) => setSearchQuery(e.target.value)}
                                                        />
                                                        <button
                                                          class="btn btn-outline-success"
                                                          type="submit"
                                                        >
                                                          <i class="fa-solid fa-magnifying-glass"></i>
                                                        </button>
                                                      </div>
                                                    </form>
                                                    <ul class="navbarnav">
                                                      <li class="nav-item">
                                                        {/* <a class="nav-link" href="#">News & Articles </a> */}
                                                        <Link to="/newsarticleslisting" className="nav-link"> 
                                                          News & Articles{" "} 
                                                        </Link>
                                                      </li>
                                                      <li class="nav-item">
                                                        <Link
                                                          class="nav-link"
                                                          to="/pages/about-us"
                                                        >
                                                          How it Worked
                                                        </Link>
                                                      </li>
                                                      {isUser ? (
                                                        <li className="nav-item">
                                                          <div className="dropdown">
                                                         
                                                            <button
                                                            
                                                              className="btn btn-secondary dropdown-toggle btn-user"
                                                              type="button"
                                                              id="dropdownMenuButton1"
                                                              data-bs-toggle="dropdown"
                                                              aria-expanded="false"
                                                              
                                                            >
                                                              <img src="../assets/images/user-icon-png.webp" alt="user-icon-png"class="img-icon"   />
                                                              {/* {username} */}
                                                            </button>
                                                            <ul
                                                              className="dropdown-menu dropdown-li"
                                                              aria-labelledby="dropdownMenuButton1"
                                                            >
                                                              <li>
                                                                <Link
                                                                  className="dropdown-item"
                                                                  to="/profile"
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                  }}
                                                                >
                                                                  My Profile
                                                                </Link>
                                                              </li>
                                                              <li>
                                                                <a
                                                                  className="dropdown-item"
                                                                  href=""
                                                                  onClick={
                                                                    handlelogout
                                                                  }
                                                                  style={{
                                                                    color:
                                                                      "black",
                                                                  }}
                                                                >
                                                                  Logout
                                                                </a>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </li>
                                                      ) : (
                                                        <li className="nav-item lgin">
                                                          <a
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModalsign"
                                                            onClick={
                                                              openLoginModal
                                                            }
                                                          >
                                                            Signin
                                                          </a>{" "}
                                                          /{" "}
                                                          <a
                                                            href="#"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal2"
                                                            onClick={openSignupModal}
                                                          >
                                                            Up
                                                          </a>
                                                        </li>
                                                      )}
                                                    </ul>
                                                  </nav>
                                                </div>
                                                <Sidebarview isActive={isActive} activesidebar={activesidebar} ></Sidebarview>
                                                <Login
                                                  show={showLoginModal}
                                                  onClose={closeLoginModal}
                                                />
                                                <Signup
                                                show={showSignupModal}
                                                onClose={closeSignupModal}
                                                />
                                                {/* <ChangePassword></ChangePassword> */}
                                                <ForgotPassword></ForgotPassword>
                                                {/* <EditProfile></EditProfile> */}
                                              </header>
                                              <Primedeal></Primedeal>
                                              <Primesale></Primesale>
                                            </>
                                          );}