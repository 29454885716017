import React from 'react';

const styles = {
  loadingOverlay: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.75)',
    padding: '10px',
    borderRadius: '8px',
    zIndex: 9999,
    textAlign: 'center',
    width: '200px',
  },
  loadingText: {
    color: '#fff',
    fontSize: '24px',
    fontWeight: 'bold',
  },
};

const LoadingOverlay = () => {

  return (
    <div style={styles.loadingOverlay}>
      <div style={styles.loadingText}>Loading...</div>
    </div>
  );
};

export default LoadingOverlay;