import { fetchdealscount } from "./api"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getDealsCount = createAsyncThunk(
    "dealscount/get",
    async (formData, { rejectWithValue }) => {
      try {
        const response = await fetchdealscount(formData);

        return response.data;
      } catch (error) {
        return rejectWithValue(error.response);
      }
    }
  );

  const DealsCountSlice = createSlice({
    name: "dealscount",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
    cleardealcountAction: (state) => {
        state.success = false;
        state.error = null;
        state.submitting = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getDealsCount.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(getDealsCount.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(getDealsCount.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        })
      
    },
  });
    export default DealsCountSlice.reducer;
    export const { cleardealcountAction } = DealsCountSlice.actions;