import React, { useEffect, useState } from "react";
import Primesale from "../../Layouts/Deal/Primesale.js";
import Primedeal from "../../Layouts/Deal/Primedeal.js";
import { useParams } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo";
import CategoriesSection from "../Home/CategoriesSection.js";
import { useSelector, useDispatch } from "react-redux";
import Dailydealifs from "../../Tools/Dailydealifs/index.js";
import { getDealsContent,clearAction,  } from "../../../Reducer/dealsSlice.js";
import { getsearchedDeals } from "../../../Reducer/dealsSlice.js";
import { useSearchParams } from 'react-router-dom';
import Pagination from "../../Tools/Pagination/index.js";
import LoadingOverlay from "../../Tools/Loading/index.js";
import { fetchFavoritesDeals } from "../../../Reducer/favoriteSlice.js";
import { getFilteredDeals } from "../../../Reducer/dealsSlice";

export default function DealsPage() {
  const userid = JSON.parse(localStorage.getItem("user"))?.id || null;
    const [currentPage, setCurrentPage] = useState(1);
    const [searchParams] = useSearchParams();
    const [searchQuery, setSearchQuery] = useState(searchParams.get("search") || "");
    const dispatch = useDispatch();
    const dealscontent = useSelector((state) => state.dealscontent.content);
    const dealssuccessData = useSelector((state) => state.dealscontent.success);
    const dealssearchcontent = useSelector((state)=> state.dealscontent.searchdeal);
    const dealssearchsuccess = useSelector((state)=> state.dealscontent.searchsuccess);
    const filterproductcontent = useSelector((state)=> state.dealscontent.content);
    const filterproductsucess = useSelector((state)=> state.dealscontent.filtersuccess);
    const favoritedealsuccessData = useSelector(
      (state) => state.favoritedeals.success
    );
    const favoritedealContent = useSelector(
      (state) => state.favoritedeals.favoritedealcontent
    );
    const FavoriteAddsuccessData = useSelector(
      (state) => state.favoritedeals.addsuccess
    );
   const FavoriteRemovesuccessData = useSelector(
     (state) => state.favoritedeals.removesuccess
   );
    const [favoritesdeals, setFavoritesDeals] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dailydeals, setdailyDeals] = useState("");
    useEffect(() => {
        const search = searchParams.get("search") || "";
        setSearchQuery(search);
      }, [searchParams]);
      const favIdMap = {};

      

    useEffect(() => {
        if(searchQuery){
            setLoading(true);
            let formData = {
                page: currentPage,
                limit: 20,
                s: searchQuery
            }
            dispatch(getsearchedDeals(formData));
        }
        else{
          setLoading(true);
        let formData = {
            superdeal: 0,
            limit: 20,
            page: currentPage,
          };
          dispatch(getDealsContent(formData));
          }
        }, [searchQuery, currentPage]);

  useEffect(() => {
    if (dealscontent && dealssuccessData) {
      setdailyDeals(dealscontent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealssuccessData]);
  useEffect(()=>{
    if(filterproductcontent && filterproductsucess){
        console.log(filterproductcontent, 'deals');
    }
},[filterproductsucess])
  useEffect(() => {
    if (userid) {
      let formData = {
        userid: userid,
      };
      dispatch(fetchFavoritesDeals(formData));
    }
  }, [userid,FavoriteAddsuccessData, FavoriteRemovesuccessData]);
  useEffect(()=>{
    if (favoritedealsuccessData && favoritedealContent) {
      dispatch(clearAction());
          setFavoritesDeals(favoritedealContent);
          favoritedealContent.forEach((ele) => {
            favIdMap[ele.deal.ID] = ele.id; 
        });
          setLoading(false);
      }
  },[favoritedealsuccessData, favoritedealContent, FavoriteAddsuccessData,FavoriteRemovesuccessData ])
  useEffect(() => {
    if (dealssearchcontent && dealssearchsuccess) {
      setdailyDeals(dealssearchcontent);
      setLoading(false);
      dispatch(clearAction());
    }
  }, [dealssearchsuccess]);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= 4) {
      window.scrollTo({top: 0});
      setCurrentPage(page);
    }
  };
  return (
    <>
      <CategoriesSection />
      <section class="shop deals_shop">
      <div className="container">
        <div className="row">
        {loading ? (
            <LoadingOverlay/> 
          ) : (
            dailydeals.length > 0 &&
            dailydeals.map((ele) => {
          const favoriteDeal = favoritesdeals.find(fav => fav.deal.ID === ele.ID); 
              const isFav = Boolean(favoriteDeal);
              const favid = favoriteDeal ? favoriteDeal.id : null; 
          // const currentFavId = isFav ? favIdMap[ele.ID] : null;
          return(
            <Dailydealifs deal={ele} isFav={isFav} favid={favid}></Dailydealifs>) 
          }
        )
        )
      }
        </div>

        <Pagination currentPage={currentPage} totalPages={4} onPageChange={handlePageChange}></Pagination>
      </div>

      </section>
      <Footerinfo></Footerinfo>
    </>
  );
}
