import React, {useEffect, useState} from "react";
import Owlifslist from "../Tools/Owlifslist";
import Owlselecteditem from "../Tools/Owlselecteditem";
import { FetchFeaturedCategories, clearcatAction } from "../../Reducer/categoriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { FetchDealsByCategories, clearAction  } from "../../Reducer/dealsbyCategorySlice.js";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function Owlcaraousedata() {

    const dispatch = useDispatch();
    const featuredcategdata = useSelector((state)=> state.categories.featured);
    const featuredcategsuccess = useSelector((state)=> state.categories.featsuccess);
    const [activeTab, setActiveTab] = useState(null);
    const [dealsbyCat, setDealsByCat] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dealsloading , setDealsLoading] = useState(true);
    const [featcat, setFeatCat] = useState([]);
    useEffect(()=> {
        setLoading(true);
        dispatch(FetchFeaturedCategories());
    },[]);
    useEffect(()=>{
        if(featuredcategdata && featuredcategsuccess){
            setFeatCat(featuredcategdata);
            setLoading(false);
            setActiveTab(featuredcategdata[0]);
            dispatch(clearcatAction());
        }
    },[featuredcategsuccess]);
    const dealsByCategoryContent = useSelector(
        (state) => state.dealsbycategories.content
      );
      const dealsByCategorysuccess = useSelector(
        (state) => state.dealsbycategories.success
      );
      useEffect(() => {
        if (dealsByCategoryContent && dealsByCategorysuccess) {
          setDealsByCat(dealsByCategoryContent);
        setDealsLoading(false);
          dispatch(clearAction());
        }
      }, [dealsByCategorysuccess]);
    useEffect(()=> {
        if(activeTab){
        setDealsLoading(true);
        setDealsByCat([]);
        let formData = {
            slug: activeTab.SLUG,
            page: 1,
            limit: 6
          };
          dispatch(FetchDealsByCategories(formData));
        }
        
    },[activeTab]);
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
      };
    
    return (<>
        <section class="shop">
            <div class="container">
                {!loading && (
                  <>
          <Swiper
          className="shop_carousel nav nav-tabs"
            spaceBetween={10}
            modules={[Navigation, Scrollbar, A11y]}
            loop={false}
            navigation
            breakpoints={{
              0: { slidesPerView: 2},
              576: { slidesPerView: 3 },
              768: { slidesPerView: 5 },
              992: { slidesPerView: 5 },
              1200: {slidesPerView: 5},
              1400: { slidesPerView: 5 },
              1600: { slidesPerView: 5},
            }}
          >
          {featcat.length>0 && featcat.map((ele, index)=> (
              <SwiperSlide key={index}>
               <div
              className={`item ${activeTab.TITLE === ele.TITLE ? 'active ' : ''}`}
              id={`tab${index + 1}-tab`}
              data-bs-toggle="tab"
              data-bs-target={`#tab${index + 1}`}
              type="button"
              role="tab"
              aria-controls={`tab${index + 1}`}
              aria-selected={activeTab === `tab${index + 1}`}
              onClick={() => handleTabClick(ele)}
            >
              <div className={`images ${activeTab.TITLE === ele.TITLE ? 'imgactive ' : ''}`}>
                <p>{ele.TITLE}</p>
              </div>
            </div>
              </SwiperSlide>
          ))}
          </Swiper>
          <div className="tab-content" id="myTabContent">
          {featcat.length > 0 && featcat.map((ele, index) => (
            <div
              key={index}
              className={`tab-pane fade ${activeTab === ele ? 'show active' : ''}`}
              id={`tab${index + 1}`}
              role="tabpanel"
              aria-labelledby={`tab${index + 1}-tab`}
            >
              <div className="row">
                {dealsloading && (
                  <p>Loading...</p>
                )}
                {!dealsloading && dealsbyCat.map((item, itemIndex) => (
                  <div className="col-sm-6 col-md-4 col-xxl-2 mb-3" key={itemIndex}>
                   <Owlselecteditem data={item} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        </>
                )}

        {/* <Owlselecteditem></Owlselecteditem> */}

        
        {/* <div
            key={index}
            className={`tab-pane fade ${activeTab === `tab${index + 1}` ? 'show active' : ''}`}
            id={`tab${index + 1}`}
            role="tabpanel"
            aria-labelledby={`tab${index + 1}-tab`}
          >
            <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                <div class="row">

                <div class="col-sm-6 col-md-4 col-xxl-2 mb-3">
                    <Owlselecteditem/>
                </div>

            </div>
            </div>
            </div> */}
            </div>
        </section>
    </>)
}