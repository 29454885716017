import { getComments, clearAction, likedislikeComment } from "../../../Reducer/commentSlice"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { postComment } from "../../../Reducer/commentSlice";
import SingleComment from "./SingleComment";
import Form from "./Form";
export default function ProductComments({id}){
    const dispatch = useDispatch();
    const [comments, setComments] = useState([]);
    const [message, setMessage] = useState("");
    const username = localStorage.getItem('comment_username');
    const email = localStorage.getItem('comment_email');
    const commentsuccessData = useSelector((state)=> state.postcomments.success);
    const commentcontentData = useSelector((state)=> state.postcomments.content);
    const commentlikesuccData = useSelector((state)=> state.postcomments.likesuccess);
    const commentaddsuccessData = useSelector(
      (state) => state.postcomments.addsuccess
    );
    const commentsinteracted = JSON.parse(localStorage.getItem('commentsinteracted'));
    const nestComments = (comments) => {
      const commentMap = {};
      comments.forEach(comment => commentMap[comment.id] = {...comment, children: []});
      const nestedComments = [];
    
      comments.forEach(comment => {
        if (comment.parent_id !== 0 && commentMap.hasOwnProperty(comment.parent_id)) {
          commentMap[comment.parent_id].children.push(commentMap[comment.id]);
        } else {
          nestedComments.push(commentMap[comment.id]);
        }
      });
    
      return nestedComments;
    };
    
    useEffect(()=>{
        if(id){        
            let formData = new FormData();
            formData.append('comment_post_ID', id);
            dispatch(getComments(formData))
           
        }
    },[id, commentlikesuccData, commentaddsuccessData]);
    useEffect(()=>{
        if(commentsuccessData && commentcontentData){
            setComments(nestComments(commentcontentData));
            dispatch(clearAction());
        }
        if(commentlikesuccData){
          dispatch(clearAction());
        }
    },[commentsuccessData, commentlikesuccData]);
    const updateLocalStorage = (id) => {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      commentsInteracted.push(id);
      localStorage.setItem('commentsInteracted', JSON.stringify(commentsInteracted));
    };
    const handleChange = (event)=> {
      setMessage(event.target.value);
    }
    const likeCommentHandler = (id)=>{
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 1);
        formData.append('dislike', 0);
        formData.append('commentid', id);
        dispatch(likedislikeComment(formData));
        updateLocalStorage(id);
      }
    }
    const dislikeCommentHandler = (id) => {
      if(!checkInteracted(id)){
        let formData = new FormData();
        formData.append('like', 0);
        formData.append('dislike', 1);
        formData.append('commentid', id);
      dispatch(likedislikeComment(formData));
      updateLocalStorage(id);
      }
    }
    const checkInteracted = (id)=> {
      const commentsInteracted = JSON.parse(localStorage.getItem('commentsInteracted')) || [];
      if (commentsInteracted.includes(id)) {
        return true
      }
      else{
        return false
      }
    }
    const exisitingreplyHandler = (commentid)=>{
      let data = new FormData();
      data.append('comment_ID', commentid);
      data.append('comment_post_ID', id);
      data.append("author", username);
      data.append("email", email);
      data.append('comment', message );
      dispatch(postComment(data));

    }
    return(
        <>
        <div className="details-comments">
          <h3>Comments</h3>
         {comments.length > 0 && (
        
          <ul>
            {comments.map((ele) => (
                <>
             <SingleComment data ={ele} id={id}/>
            
             </>
            ))}
          </ul>
             )}
        </div>
            
        
        </>
    )
}

{/* <li key={ele.id}> 
<img
  className="rounded"
  src="https://batrips.com/LAUNCH/assets/hunt_images/coool.png"
  alt="profile_pic"
/>
<h4>{ele.author}</h4>
{/* <div className="star">
  <i className="bi bi-star-fill"></i>
  <i className="bi bi-star-fill"></i>
  <i className="bi bi-star-fill"></i>
  <i className="bi bi-star-fill"></i>
  <i className="bi bi-star-fill"></i>
</div> */}
{/* <p>{ele.comment}</p>

<i class="fa-regular fa-thumbs-up"></i>
{ele.like_comment}
<i class="far fa-thumbs-down"></i>
{ele.dislike_comment}
</li> */} 