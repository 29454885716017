import { fetchsimilardeals  } from "./api";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getSimilarDeals = createAsyncThunk(
  "deals/similardeals",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchsimilardeals(formData);
      return response;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const SimilardealsSlice = createSlice({
  name: "similardeals",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
  },
  reducers: {
    clearAction: (state) => {
      state.success = false;
      state.error = null;
      state.submitting = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSimilarDeals.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(getSimilarDeals.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(getSimilarDeals.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      });
  },
});
export default SimilardealsSlice.reducer;
export const { clearAction } = SimilardealsSlice.actions;