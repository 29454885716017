import Filters from "../../Tools/Filters"
export default function FiltersPage(){
    return(
        <>
           <section class="safety_tips">
           <div class="container">
        <Filters/>
        </div>
        </section>
        </>
    )
}