import React from "react";
import { Link } from "react-router-dom";
import { sitemaplinks } from "../../../data/Sitemaps/data";
export default function SiteLinks(){
    return(
        <>
         <h2>Sitemap</h2>
    <ul>
        {sitemaplinks.map((ele)=>{
            return(
                <>
                <li>
                <Link to={ele.src}>{ele.text}</Link>
                </li>
                </>
            )
        })
    }
    </ul>
        </>
    )
}