// fetchPrimeDealsTimer


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchPrimeDealsTimer } from './api';
 
 export const fetchPrimeDealsTimerContent = createAsyncThunk('primedealstimer/content', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchPrimeDealsTimer(formData);
      return response.data.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  });

  const PrimeTimerSlice = createSlice({
    name: "primetimercontent",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearptAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchPrimeDealsTimerContent.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(fetchPrimeDealsTimerContent.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(fetchPrimeDealsTimerContent.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });

  export default PrimeTimerSlice.reducer;
export const { clearptAction } = PrimeTimerSlice.actions;
