import React, { useEffect, useState } from "react";
import Dailydealdayifs from "../Tools/Dailydealdayifs";
import { dealsContent, fetchdealofdayContent } from "../../Reducer/api";
import { getDealOfDayContent, clearAction } from "../../Reducer/dealofdaySlice";
import { useDispatch, useSelector } from "react-redux";
export default function DealDay() {
  const dispatch = useDispatch();
  const [dealContent, setDealContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const dealContentsuccess = useSelector((state)=> state.dealofday.success);
  const dealContentdata = useSelector((state)=> state.dealofday.content);
  useEffect(()=>{
    if(dealContentdata && dealContentsuccess){
      setDealContent(dealContentdata);
      setLoading(false);
      dispatch(clearAction());
    }
  },[dealContentsuccess]);
  useEffect(()=>{
    dispatch(getDealOfDayContent())
  },[]);
  return (
    <>
    {!loading && (
      <>
       {dealContent.map((ele, index) => (
         <Dailydealdayifs key={index} ele={ele} />
       ))}
       </>
    )}
       
    </>
  );
}
