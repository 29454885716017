import React from "react";
import { ProductData } from "../../../data/Productdata/Productdata";
export default function ProductDetails({details}){
    return (
      <>
        <div class="in_single">
          <h3>Product Details</h3>
          <div class="single" dangerouslySetInnerHTML={{ __html: details }}>
          </div>
        </div>
      </>
    );
}