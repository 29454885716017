import React, { useEffect, useState } from "react";
import ListItem from "../../Tools/ListItem";
// Import Swiper React components
// Import Swiper styles
import { useSelector, useDispatch } from "react-redux";
import { FetchCategories, clearcatAction } from "../../../Reducer/categoriesSlice";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from "react-router-dom";
export default function CategoriesSection() {
    const categoryContent = useSelector((state)=> state.categories.content);
    const categorysuccessData = useSelector((state) => state.categories.success);
    const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState("true");
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(FetchCategories());
    }, [])
  useEffect(() => {
    if (categorysuccessData && categoryContent) {
      setCategories(categoryContent);
      setLoading(false);
      dispatch(clearcatAction());
    }
  }, [categorysuccessData, categoryContent]);

  return (
    <section className="category">
      <div className="container">
    
        <Swiper
          spaceBetween={10}
          modules={[Navigation, Scrollbar, A11y]}
          // loop={true}
          slidesPerView={1}
          navigation={{
            prevEl: '.custom-prev',
            nextEl: '.custom-next',
          }}
          breakpoints={{
            320: { slidesPerView: 3 },
            576: { slidesPerView: 3 },
            768: { slidesPerView: 3 },
            992: { slidesPerView: 6 },
            1400: { slidesPerView: 6 },
          }}
        >
          {!loading &&
            categoryContent.length > 0 &&
            categoryContent.map((ele) => (
              <SwiperSlide key={ele.ID}>
                <Link to={`/dealsdetails/${ele.ID}`}>
                  <div className="CategoryItem">
                    <img src={ele.thumb} alt={ele.TITLE} />
                    <p>{ele.TITLE}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
             
        </Swiper>
        <div className="custom-navigation">
        <button className="custom-prev">
          &#10094; {/* Left Arrow Icon */}
        </button>
        <button className="custom-next">
          &#10095; {/* Right Arrow Icon */}
        </button>
      </div>
      </div>
    </section>
  );
}
// https://docs.google.com/spreadsheets/d/1tEP_nMC5ZjytQyKGD3L6M4FOiVhJbHdg-Bu6eEOxNLM/edit#gid=0