import React, { useEffect, useState } from "react"
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import { Link } from "react-router-dom";
import Footerinfo from "../../Home/Footerinfo.js";
import { FetchStaticContent, clearAction } from "../../../Reducer/staticconSlice.js";
import { useDispatch, useSelector } from "react-redux";

export default function PrivacyPolicy(){
    const dispatch = useDispatch();
    const privacypolcontent = useSelector((state)=> state.staticcontent.content);
    const privacypolsuccess = useSelector((state)=> state.staticcontent.success);
    const [privacypol, setPrivacypol] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        let formdata = new FormData();
        formdata.append("slug", "Privacy-Policy");
        dispatch(FetchStaticContent(formdata))
    },[]);
    useEffect(()=>{
        if(privacypolcontent && privacypolsuccess){
            setPrivacypol(privacypolcontent.content);
            setLoading(false);
        }
    },[privacypolsuccess]);
    return(
        <>
        <section class="safety_tips">
        <div class="container">
        <h6><Link to="/">Home</Link>&lt; Privacy Policy </h6>
        {!loading && (
        <div dangerouslySetInnerHTML={{ __html: privacypol }}></div>
        )
    }
        </div>
        </section>
        <Footerinfo/>
        </>
    )
}