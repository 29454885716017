import React, { useEffect, useState } from 'react';
export const datas = [
  {
    name: "Home",
    image: "/assets/images/icon1.png",
    src: "/",
  },
  {
    name: "Hot",
    image: "/assets/images/icon2.png",
    src: "/dealsdetails/1422",
  },
  {
    name: "Recharge",
    image: "/assets/images/icon3.png",
    src: "/dealsdetails/17",
  },
  {
    name: "Stores",
    image: "/assets/images/icon4.png",
    src: "/stores",
  },
  {
    name: "Freebies",
    image: "/assets/images/icon5.png",
    src: "/dealsdetails/free-sample",
  },
  {
    name: "Forum",
    image: "/assets/images/icon6.png",
    src: "https://www.indiafreestuff.in/forum",
  },
  {
    name: "Deals",
    image: "/assets/images/icon77.png",
    src: "/deals",
  },
  {
    name: "Coupons",
    image: "/assets/images/icon8.png",
    src: "/coupons",
  },
  {
    name: "Received",
    image: "/assets/images/icon9.png",
    src: "https://www.indiafreestuff.in/forum/forum/30-product-review-received-stuff/",
  },
  {
    name: "Ask A deal",
    image: "/assets/images/icon10.png",
    src: "https://www.indiafreestuff.in/forum/forum/19-ask-for-a-deal/",
  },
  {
    name: "Android App",
    image: "/assets/images/icon11.png",
    src: "https://play.google.com/store/apps/details?id=com.indiafreestuff.indiafreestuff.in&hl=en",
    target: '_blank'
  },
  {
    name: "Privacy Policy",
    image: "/assets/images/icon12.png",
    src: "/pages/Privacy-Policy",
  },
  {
    name: "Terms and Conditions",
    image: "/assets/images/icon13.png",
    src: "pages/Terms-and-Conditions",
  },
  {
    name: "IOS App",
    image: "/assets/images/icon14.png",
    src: "https://itunes.apple.com/us/app/indiafreestuff-deals-coupons/id1435696264",
    target: '_blank'
  },
];

