export const headerData=[
    {
        "id":"1",
        "name":"Buy a Car",
        "segment":"BuyCar"
    },
    {
        "id":"2",
        "name":"Rent a Car",
        "segment":"RentCar"
    },
    {
        "id":"3",
        "name":"Sell Your Car",
        "segment":"SellYourCar"
    },
    {
        "id":"4",
        "name":"Rent Your Car",
        "segment":"RentYourCar"
    },
    {
        "id":"5",
        "name":"Check Your Car",
        "segment":"CheckYourCst"
    },
    {
        "id":"6",
        "name":"Electric cars",
        "segment":"ElectricCars"
    },
    {
        "id":"7",
        "name":"Classic cars",
        "segment":"classic_cars"
    }
];
export const footerData=[
    {
        "id":"1",
        "name":"Company",
        "segment":"company",
        "sub_list":[{
            "id":"1",
            "name":"About Ako Sayara",
            "segment":"aboutSayara"
        },
        {
            "id":"2",
            "name":"Contact Us",
            "segment":"contact"
        },
        {
            "id":"3",
            "name":"Careers",
            "segment":"careers"
        },
        {
            "id":"4",
            "name":"Privacy policies",
            "segment":"policies"
        },
        {
            "id":"5",
            "name":"Terms & Conditions",
            "segment":"conditions"
        },
        {
            "id":"6",
            "name":"Brand Advertising on Ako Sayara",
            "segment":"advertising"
        },
        {
            "id":"7",
            "name":"Cookies",
            "segment":"cookies"
        }
    ]
    },
    {
        "id":"2",
        "name":"Products & Services",
        "segment":"product_service",
        "sub_list":[{
            "id":"1",
            "name":"Buy your perfect car",
            "segment":"buy_perfect_cars"
        },
        {
            "id":"4",
            "name":"Sell Your Car",
            "segment":"SellYourCar"
        },
        {
            "id":"3",
            "name":"Rent your perfect car",
            "segment":"rent_perfect_cars"
        },
        {
            "id":"2",
            "name":"Rent Your Car",
            "segment":"RentYourCar"
        },
        {
            "id":"5",
            "name":"Check Your Car",
            "segment":"check_your_cars"
        }
    ]
    },
    {
        "id":"3",
        "name":"Buying advice",
        "segment":"buying_advice",
        "sub_list":[{
            "id":"1",
            "name":"Buying guide",
            "segment":"buying_guide"
        },
        {
            "id":"3",
            "name":"Selling guide",
            "segment":"selling_guide"
        },
        {
            "id":"2",
            "name":"Renting guide",
            "segment":"rent_cars"
        },

        {
            "id":"4",
            "name":"Rent your car guide",
            "segment":"rent_your_car"
        },
        {
            "id":"5",
            "name":"Why Ako Sayara",
            "segment":"why_ako_sayara"
        }
    ]
    },
    {
        "id":"4",
        "name":"Quick search",
        "segment":"quick_search",
        "sub_list":[{
            "id":"1",
            "name":"Car brands",
            "segment":"Car_brands"
        },
        {
            "id":"2",
            "name":"Find a car dealer",
            "segment":"find_a_car"
        },
        {
            "id":"3",
            "name":"Classic cars",
            "segment":"classic_cars"
        },
        {
            "id":"4",
            "name":"Cheap cars",
            "segment":"cheap_cars"
        },
        {
            "id":"5",
            "name":"New car deals",
            "segment":"new_car_deals"
        }
    ]
    }
];

export const TostOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };
export const social=[
    {
        "id":"1",
        "name":"Facebook",
        "icon":"fa-brands fa-facebook",
        "link":"https://www.facebook.com"
    },
    {
        "id":"2",
        "name":"Twitter",
        "icon":"fa-brands fa-twitter",
        "link":"https://twitter.com"
    },
    {
        "id":"3",
        "name":"Linkedin",
        "icon":"fa-brands fa-linkedin",
        "link":"https://in.linkedin.com"
    },
    {
        "id":"4",
        "name":"Youtube",
        "icon":"fa-brands fa-youtube",
        "link":"https://www.youtube.com/"
    },
    {
        "id":"5",
        "name":"Instagram",
        "icon":"fa-brands fa-instagram",
        "link":"https://www.instagram.com"
    },
    {
        "id":"6",
        "name":"Tiktok",
        "icon":"fa-brands fa-tiktok",
        "link":"https://www.tiktok.com"
    },
];

export const checkbox=[
    {
        "id":1,
        "name":"ABS",
        "value":"abc"
    },
    {
        "id":2,
        "name":"Electric seats",
        "value":"electric_seats"
    },
    {
        "id":3,
        "name":"Power door locks",
        "value":"power_door_locks"
    },
    {
        "id":4,
        "name":"Air condition seats",
        "value":"air_condition_seats"
    },
    {
        "id":45,
        "name":"Airbags",
        "value":"airbags"
    },
    {
        "id":5,
        "name":"Head screen dashboard projector",
        "value":"head_screen_dashboard_projector"
    },
    {
        "id":6,
        "name":"Rear view camera",
        "value":"rear_view_camera"
    },
    {
        "id":345,
        "name":"Smart Entry",
        "value":"smart_entry"
    },
    {
        "id":7,
        "name":"Air condition",
        "value":"air_condition"
    },
    {
        "id":64,
        "name":"Cruise control",
        "value":"cruise_control"
    },
    {
        "id":8,
        "name":"Heated seats",
        "value":"heated_seats"
    },
    {
        "id":9,
        "name":"Sunroof",
        "value":"sunroof"
    },
    {
        "id":10,
        "name":"AUX/USB Plug",
        "value":"plug"
    },
    {
        "id":11,
        "name":"Heated steering wheel",
        "value":"heated_steering_wheel"
    },
    {
        "id":3,
        "name":"Smart parcking",
        "value":"smart_parcking"
    },
    {
        "id":4,
        "name":"Bluetooth",
        "value":"bluetooth"
    },
    {
        "id":5,
        "name":"Keyless entry",
        "value":"keyless_entry"
    },
    {
        "id":6,
        "name":"Touchscreen",
        "value":"touchscreen"
    },
    {
        "id":435,
        "name":"USB type C",
        "value":"usb_type_c"
    },
    {
        "id":7,
        "name":"Cooling box fridge",
        "value":"cooling_box_fridge"
    },
    {
        "id":8,
        "name":"Leather seats",
        "value":"leather_seats"
    },
    {
        "id":43,
        "name":"Led lights",
        "value":"led_lights"
    },
    {
        "id":9,
        "name":"Wireless charging",
        "value":"wireless_charging"
    },
    {
        "id":4,
        "name":"Electric Doors",
        "value":"electric_doors"
    },
    {
        
        "id":43,
        "name":"Electric mirrors",
        "value":"electric_mirrors"
    },
    {
        "id":5,
        "name":"Message seats",
        "value":"message_seats"
    },
    {
        "id":8,
        "name":"Navigation system",
        "value":"navigation_system"
    },
    {
        "id":6,
        "name":"Xenon lights",
        "value":"xenon_lights"
    },
    {
        "id":9,
        "name":"Parking sensors",
        "value":"parking_sensors"
    }
]

export const homebanner={
    cities:[],
    distance:[],
    make:[],
    model:[],
    min_price:[],
    max_price:[]
}
export const brandSection={
    topfour:[],
    all:[]
}

export const errorSms={
    first:"Something went wrong"
}

export const phoneRegExp="/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";

export const homeOptions = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 5000,
    margin: 0,
    responsiveClass: true, 
    responsive: {
      0: { items: 1.5, nav: false },
      576: { items: 2, nav: true },
      768: { items: 2, nav: true },
      992: { items: 3, nav: true },
      1200: { items: 3, nav: true }
    }
  };

  export const homeSelect = {
    loop: true,
    autoplay: false,
    autoplayTimeout: 5000,
    margin: 0,
    responsiveClass: true, 
    responsive: {
        0: { items: 2.5, nav: false },
        576: { items: 2.5, nav: true }
    }
  };

export function isFileSizeValid(files, maxSize) {
    let valid = true
    if (files) {
        files.map(file => {
            const fileSize = file.size
            if (fileSize > maxSize) {
                valid = false
            }
        })
    }
    return valid
}

export function isFileTypesValid(files, authorizedExtensions) {
    let valid = true
    if (files) {
        files.map(file => {
            if (!authorizedExtensions.includes(file.type)) {
                valid = false
            }
        })
    }
    return valid
}

export function convertBase64 (file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }