import React, { useEffect,useState } from 'react';
import { NavLink } from "react-router-dom";

export default function BasicDetail() {
  const [selectedOption, setSelectedOption] = useState('email');
  
  return (
  <section class="login ">
         <div class="container">
            <div class="advance_search newt mt-0">
              <h6 class="text-start mt-0"><NavLink to={'/Login'}><img src="assets/images/backbt.png"/> {'Go back'}</NavLink></h6>
            </div>
            <div class="form-signin signin2">
               <NavLink className="logo d-none" to={'/'}><img src={`assets/images/MainIcon.png`}/></NavLink>
               <h1>{'Basic Details'}</h1>
               <ul class="nav nav-tabs" id="myTab" role="tablist">
               </ul>
               <div class="tab-content" id="myTabContent">
               </div>
            </div>
         </div>
      </section>);
}