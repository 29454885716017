import React, { useEffect, useState } from "react";
import Primesale from '../../Layouts/Deal/Primesale.js';
import Primedeal from '../../Layouts/Deal/Primedeal.js';
import Footerinfo from "../../Home/Footerinfo";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FetchStaticContent, clearAction } from "../../../Reducer/staticconSlice.js";
export default function Aboutus(){
    const dispatch = useDispatch();
    const [aboutcontent, setAboutContent] = useState("");
    const aboutusContentData = useSelector((state)=> state.staticcontent.content);
    const aboutuusSuccessData = useSelector((state) => state.staticcontent.success);
    useEffect(()=>{
        let formdata = new FormData();
        formdata.append("slug", "about-us");
        dispatch(FetchStaticContent(formdata));
    }, []);
    
    useEffect(() => {
      if (aboutusContentData && aboutuusSuccessData) {
        setAboutContent(aboutusContentData.content);
        dispatch(clearAction());
      }
    }, [aboutusContentData, aboutuusSuccessData]);
    return (<>

        <section class="safety_tips aboutus">
            <div class="container">
                <h6><Link to="/">Home</Link> &lt; About Us</h6>
                <div class="tips"  >
                    <h2>About Us</h2>
                    <div dangerouslySetInnerHTML={{ __html: aboutcontent }}>
                    </div>
                    {/* <p>Indiafreestuff is daily updated blog for Indians which posts Free Samples, Online discounted deals, Free genuine Software License. Coupons, Contest and shopping tips & articles.</p>
                    <h5>Disclosures:-</h5>
                    <ul>
                        <li>Indiafreestuff.in may earn commission on your qualifying purchases from our link such as Amazon or Flipkart.</li>
                    </ul>
                    <h5>Our Mission:</h5>
                    <ul>
                        <li>Be the leading Indian provider of high-quality, Working offers, Samples, Coupons, Contest & Articles</li>
                        <li>To save money for our readers by providing good money saver deals, coupons, freebies & tips</li>
                    </ul>
                    <p><a href="#">Get free stuffs & discounted deals, save money, spend less and live well</a></p> */}
                </div>
            </div>
        </section>
        <Footerinfo></Footerinfo>
    </>)
}
