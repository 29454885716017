import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCategories, fetchfeaturedCategories } from "./api";

export const FetchCategories = createAsyncThunk(
  "categories/getcategories",
  async (_, { rejectWithValue }) => {
    try {
        const response = await fetchCategories();
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const FetchFeaturedCategories = createAsyncThunk(
  "categories/getfeaturecategories",
  async (_, { rejectWithValue }) => {
    try {
        const response = await fetchfeaturedCategories();
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
const CategoriesSlice = createSlice({
  name: "categories",
  initialState: {
    submitting: false,
    error: null,
    success: false,
    content: [],
    featured: [],
    featsuccess: false
  },
  reducers: {
    clearcatAction: (state) => {
      state.success = false;
      state.error = null;
      state.featsuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(FetchCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.content = action.payload;
        state.success = true;
        state.error = null;
      })
      .addCase(FetchCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(FetchFeaturedCategories.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.featsuccess = false;
      })
      .addCase(FetchFeaturedCategories.fulfilled, (state, action) => {
        state.submitting = false;
        state.featured = action.payload;
        state.featsuccess = true;
        state.error = null;
      })
      .addCase(FetchFeaturedCategories.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.featsuccess = false;
      });
  },
});
export default CategoriesSlice.reducer;
export const { clearcatAction } = CategoriesSlice.actions;
