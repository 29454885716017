import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { newsarticleContent } from "./api";
import { fetchcouponslist } from './api';
export const FetchCoupons = createAsyncThunk('coupons/fetchcoupons', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchcouponslist(formData);
      return response.responseData.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const CouponsSlice = createSlice({
    name: "coupons",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      content: [],
    },
    reducers: {
      clearAction: (state) => {
        state.success = false;
        state.error = null;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(FetchCoupons.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.success = false;
        })
        .addCase(FetchCoupons.fulfilled, (state, action) => {
          state.submitting = false;
          state.content = action.payload;
          state.success = true;
          state.error = null;
        })
        .addCase(FetchCoupons.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.success = false;
        });
    },
  });
//   export const SingleFetchNewsArticleContent = createAsyncThunk('singlenewsarticle/content', async (formData, { rejectWithValue }) => {
//     try {
//       const response = await singleFetchNewsArticleContent(formData);
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   });
export default CouponsSlice.reducer;
export const { clearAction } = CouponsSlice.actions;