import { newcontactsubmit, fetchcontactus} from "./api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const ContactusSubmit = createAsyncThunk('contactus/submit', async (formData, { rejectWithValue }) => {
    try {
      const response = await newcontactsubmit(formData);
      return response.responseData;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });

  export const  FetchContactUs = createAsyncThunk('contactus/fetch', async (formData, { rejectWithValue }) => {
    try {
      const response = await fetchcontactus();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  });
  const ContactUsSlice = createSlice({
    name: "contactus",
    initialState: {
      submitting: false,
      error: null,
      success: false,
      subsuccess: false,
      content: [],
    },
    reducers: {
      clearcontactAction: (state) => {
        state.success = false;
        state.error = null;
        state.submitting = false;
        state.subsuccess = false;
      },
    },
    extraReducers: (builder) => {
      builder
      .addCase(FetchContactUs.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(FetchContactUs.fulfilled, (state, action) => {
        state.submitting = false;
        state.success = true;
        state.content = action.payload;
        state.error = null;
      })
      .addCase(FetchContactUs.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
        .addCase(ContactusSubmit.pending, (state) => {
          state.submitting = true;
          state.error = null;
          state.subsuccess = false;
        })
        .addCase(ContactusSubmit.fulfilled, (state, action) => {
          state.submitting = false;
          state.subsuccess = true;
          state.error = null;
        })
        .addCase(ContactusSubmit.rejected, (state, action) => {
          state.submitting = false;
          state.error = action.payload;
          state.subsuccess = false;
        });
      
    },
  });

export default ContactUsSlice.reducer;
export const { clearcontactAction } = ContactUsSlice.actions;
